export function getCurrentDate(language) {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const date = new Date();

  switch (language) {
    case "en":
      return date.toLocaleDateString("en-US", options);
    case "de":
      return date.toLocaleDateString("de-DE", options);
    default:
      return date.toLocaleDateString("de.DE", options);
  }
}
