import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { kpiInformation } from "../data/resultLookUps";
import { DefaultEcgColors } from "../scenes/sharedStyles";
import { backend_uri } from "../utils/config_reader";

export const fetchUserSettings = createAsyncThunk(
  "user/fetchUserSettings",
  async (_, { getState, dispatch }) => {
    try {
      const response = await axios.get(`${backend_uri}/api/user/settings/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching user settings:", error);
      throw error;
    }
  }
);

export const updateUserSettings = createAsyncThunk(
  "user/updateUserSettings",
  async (updatedSettings, { getState, dispatch }) => {
    try {
      const response = await axios.post(
        `${backend_uri}/api/user/settings/`,
        updatedSettings,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error updating user settings:", error);
      throw error;
    }
  }
);

const initialState = {
  themePreference: "light",
  languagePreference: "de",
  kpiOrder: Object.keys(kpiInformation),
  ecgViewerColors: DefaultEcgColors(),
  leadsPreference: [
    "I",
    "II",
    "III",
    "aVR",
    "aVL",
    "aVF",
    "V1",
    "V2",
    "V3",
    "V4",
    "V5",
    "V6",
  ],
  speedPreference: 25,
  ecgProfiles: [],
  singleColumn: false,
  searchText: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    changeThemePreference(state, action) {
      state.themePreference = action.payload;
      state.ecgViewerColors = DefaultEcgColors(action.payload);
    },
    changeLanguagePreference(state, action) {
      state.languagePreference = action.payload;
    },
    changeKpiOrder(state, action) {
      state.kpiOrder = action.payload;
    },
    changeEcgViewerColors(state, action) {
      state.ecgViewerColors = { ...state.ecgViewerColors, ...action.payload };
    },
    changeLeadsPreference(state, action) {
      state.leadsPreference = action.payload;
    },
    changeSpeedPreference(state, action) {
      state.speedPreference = action.payload;
    },
    changeEcgProfiles(state, action) {
      state.ecgProfiles = action.payload;
    },
    changeSingleColumn(state, action) {
      state.singleColumn = action.payload;
    },
    changeSearchText(state, action) {
      state.searchText = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserSettings.fulfilled, (state, action) => {
        return { ...state, ...action.payload };
      })
      .addCase(updateUserSettings.fulfilled, (state, action) => {
        return { ...state, ...action.payload };
      });
  },
});

export const {
  changeThemePreference,
  changeLanguagePreference,
  changeKpiOrder,
  changeEcgViewerColors,
  changeLeadsPreference,
  changeSpeedPreference,
  changeEcgProfiles,
  changeSingleColumn,
  changeSearchText,
} = userSlice.actions;

export default userSlice.reducer;
