import { CssBaseline, ThemeProvider } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./auth/AuthContext";
import PrivateRoute from "./auth/PrivateRoute";
import About from "./scenes/About";
import Analyse from "./scenes/Analyse";
import Result from "./scenes/Analyse/result";
import Config from "./scenes/Config";
import LoginPage from "./scenes/Login";
import FAQ from "./scenes/faq";
import Layout from "./scenes/global/Layout";
import {
  changeEcgProfiles,
  changeEcgViewerColors,
  changeKpiOrder,
  changeLanguagePreference,
  changeLeadsPreference,
  changeSpeedPreference,
  changeThemePreference,
} from "./state/userSlice";
import { ColorModeContext, useMode } from "./theme";
import DataTransfer from "./utils/dataTransfer";

function App() {
  const [theme, colorMode] = useMode();
  const dispatch = useDispatch();
  const { setMode } = useContext(ColorModeContext);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    const initializeApp = async () => {
      if (!localStorage.getItem("access")) return;
      const userSettings = await DataTransfer.fetchUserSettings();
      if (userSettings) {
        dispatch(changeThemePreference(userSettings.data.themePreference));
        dispatch(
          changeLanguagePreference(userSettings.data.languagePreference)
        );
        dispatch(changeEcgViewerColors(userSettings.data.ecgViewerColors));
        dispatch(changeKpiOrder(userSettings.data.kpiOrder));
        dispatch(changeLeadsPreference(userSettings.data.leadsPreference));
        dispatch(changeSpeedPreference(userSettings.data.speedPreference));
        dispatch(changeEcgProfiles(userSettings.data.ecgProfiles));
      }
    };
    setMode(user.themePreference);
    initializeApp();
  }, [dispatch, user.themePreference, setMode]);

  return (
    <BrowserRouter>
      <ColorModeContext.Provider value={colorMode}>
        <AuthProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <div className="app">
              <main className="content">
                <Routes>
                  <Route path="/login" element={<LoginPage />} />
                  <Route
                    element={
                      <PrivateRoute>
                        <Layout />
                      </PrivateRoute>
                    }
                  >
                    <Route path="/analyse" element={<Analyse />} />
                    <Route path="/faq" element={<FAQ />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/config" element={<Config />} />
                    <Route path="/result/:ticket" element={<Result />} />
                  </Route>
                  <Route path="*" element={<Navigate to="/analyse" />} />
                </Routes>
              </main>
            </div>
          </ThemeProvider>
        </AuthProvider>
      </ColorModeContext.Provider>
    </BrowserRouter>
  );
}

export default App;
