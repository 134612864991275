import { Box, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AccordionContent, AccordionWrapper } from "../../components/Accordion";
import Header from "../../components/Header";
import {
  ErrorQuestions,
  GeneralQuestions,
  TechQuestions,
} from "../../data/faqs";
import { styles } from "../sharedStyles";

const FAQ = () => {
  const theme = useTheme();

  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box sx={styles(theme).mainContent}>
      <Header title="FAQ" subtitle={t("scenes.faq.heading")} />

      <Typography variant="h4" color={theme.palette.text.light}>
        {t("scenes.faq.generalQuestions.heading")}
      </Typography>
      <AccordionWrapper>
        {GeneralQuestions().map((question, index) => (
          <AccordionContent
            key={index}
            panel={question.p_name}
            header={question.question}
            handleChange={handleChange}
            expanded={expanded}
          >
            {question.texts.map((text, index) => (
              <Typography key={index} variant="h6">
                {text}
              </Typography>
            ))}
          </AccordionContent>
        ))}
      </AccordionWrapper>

      <Typography variant="h4" color={theme.palette.text.light}>
        {t("scenes.faq.techQuestions.heading")}
      </Typography>
      <AccordionWrapper>
        {TechQuestions().map((question, index) => (
          <AccordionContent
            key={index}
            panel={question.p_name}
            header={question.question}
            handleChange={handleChange}
            expanded={expanded}
          >
            {question.texts.map((text, index) => (
              <Typography key={index} variant="h6">
                {text}
              </Typography>
            ))}
          </AccordionContent>
        ))}
      </AccordionWrapper>

      <Typography variant="h4" color={theme.palette.text.light}>
        {t("scenes.faq.errorQuestions.heading")}
      </Typography>
      <AccordionWrapper>
        {ErrorQuestions().map((question, index) => (
          <AccordionContent
            key={index}
            panel={question.p_name}
            header={question.question}
            handleChange={handleChange}
            expanded={expanded}
          >
            {question.texts.map((text, index) => (
              <Typography key={index} variant="h6">
                {text}
              </Typography>
            ))}
          </AccordionContent>
        ))}
      </AccordionWrapper>
    </Box>
  );
};

export default FAQ;
