export const riskList = [
  "Arterielle Hypertonie",
  "Dyslipidämie",
  "Diabetes Mellitus",
  "RaucherIn",
  "Familiäre Veranlagungen",
  "Fettleibigkeit",
  "Koronare Herzkrankheit",
  "Perkutane Koronarintervention (PCI)",
  "Koronararterielle Bypass-Operation (CABG)",
  "Mitralklappeninsuffizienz",
];
