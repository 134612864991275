const config = {
  globalProps: {}, //global version of the "props" parameter

  measurement: {
    closestNode: -1, //datapoint closest to pointer
    memNode: null, //first node clicked
    measure: [], //datapoint fixed by clicking
    activeGraph: {
      //Which graph the pointer is currently over
      column: -1,
      row: -1,
    },
  },

  ruler: {
    rulerAngle: 0, //angle of the ruler measure method
    rulerTurnActive: false, //if the ruler is currently being rotated
    rulerAngleChanged: false, //if the angle has changed since the turn has been started
  },

  movement: {
    zoomFactor: 1, //zoom multiplier
    moveActive: false, //if the mouse button is clicked
    markActive: false, //if the mark tool is activated and the mouse button is clicked
    x: 0, //shift on the x axis
    y: 0, //shift on the y axis
    changed: false, //if the canvas has been moved recently
    lastMoveEvent: null, //latest MouseMove event
  },

  marking: [],

  canvas: {
    element: null, //baseline canvas element
    highlight: null, //canvas for the explainability
    grid: null, //canvas for the millimeter grid
    graph: null, //canvas for the ecg
    measure: null, //canvas for the measurement tool
    marking: null, //canvas for the markings
  },

  size: {
    width: 500,
    height: 400, //width and height resolution per graph
    rows: 0,
    cols: 0, //number of rows and columns of ecgs
  },

  scale: {
    duration: 5000, //Duration of the ECG in Milliseconds
    mmPerS: 0, //Millimeter per Second
    cmscale: 1, //Pixels per Centimeter
    cmscaleHeight: 1, //Pixels per Centimeter
    tickFactor: 1, //Pixels per Tick
  },

  offsets: {
    top: 1.5, //offset between the top border and the first graph
    bottom: 1.5, //offset between the bottom border and the last graph
    left: 4, //offset between the left border and the first graph
    right: 1, //offset between the right border and the last graph
    vertical: 3, //vertical offset inbetween the graphs
    horizontal: 14, //horizontal offset inbetween the graphs
  },
};

export default config;
