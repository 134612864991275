import { Save } from "@mui/icons-material";
import {
  Box,
  IconButton,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AccordionContent, AccordionWrapper } from "../../components/Accordion";
import { LabelledInput } from "../../components/Config/LabelledInputs";
import { languages } from "../../data/languages";
import { setMessage, setSeverity } from "../../state/snackbarSlice";
import {
  changeLanguagePreference,
  changeThemePreference,
  updateUserSettings,
} from "../../state/userSlice";
import { ColorModeContext } from "../../theme";
import DataTransfer from "../../utils/dataTransfer";
import { styles } from "./settingStyles";

const UserSettings = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const [email, setEmail] = useState(localStorage.getItem("user"));
  const [passwordData, setPasswordData] = useState({
    current: "",
    new: "",
    newRepeat: "",
  });

  const { setMode } = useContext(ColorModeContext);

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleLanguageChange = (event) => {
    dispatch(changeLanguagePreference(event.target.value));
    i18n.changeLanguage(event.target.value);
    dispatch(updateUserSettings({ languagePreference: event.target.value }));
  };

  const handleThemeChange = () => {
    const newTheme = theme.palette.mode === "dark" ? "light" : "dark";
    dispatch(changeThemePreference(newTheme));
    setMode(newTheme);
    dispatch(updateUserSettings({ themePreference: newTheme }));
  };

  const saveNewPassword = async () => {
    if (passwordData.new !== passwordData.newRepeat) {
      dispatch(setMessage(t("snackbarMessages.passwordNotTheSame")));
      dispatch(setSeverity("warning"));
      return;
    } else if (passwordData.current === passwordData.new) {
      dispatch(setMessage(t("snackbarMessages.passwordNotNew")));
      dispatch(setSeverity("warning"));
      return;
    }

    try {
      const response = await DataTransfer.changePassword(
        passwordData.current,
        passwordData.new
      );

      if (response.status === 200 && response.data.message) {
        dispatch(setMessage(response.data.message));
        dispatch(setSeverity("info"));
      }
    } catch (error) {
      console.error(error);
    }

    setPasswordData({ current: "", new: "", newRepeat: "" });
  };

  return (
    <>
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <LabelledInput label={t("scenes.config.tabs.interface.language.label")}>
          <Select
            id="language-select-label"
            value={user.languagePreference}
            onChange={handleLanguageChange}
          >
            {languages.map((language, index) => (
              <MenuItem key={index} value={language.code}>
                {language.label}
              </MenuItem>
            ))}
          </Select>
        </LabelledInput>
        <LabelledInput label={t("scenes.config.tabs.interface.theme.label")}>
          <Switch
            checked={user.themePreference === "light"}
            onChange={handleThemeChange}
          />
        </LabelledInput>
        <Box marginY={theme.spacing(4)} />
        <LabelledInput
          label={t("scenes.config.tabs.userSettings.labels.email")}
        >
          <TextField value={email} onChange={(e) => setEmail(e.target.value)} />
        </LabelledInput>
      </Box>
      <AccordionWrapper>
        <AccordionContent
          panel={"change-password"}
          header={t("scenes.config.tabs.userSettings.password.header")}
          headerFontWeight="bold"
          handleChange={handleChange}
          expanded={expanded}
        >
          <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <Typography variant="body2">
              {t("scenes.config.tabs.userSettings.password.explanation")}
            </Typography>
            <LabelledInput
              label={t("scenes.config.tabs.userSettings.password.current")}
            >
              <TextField
                type="password"
                value={passwordData.current}
                onChange={(e) =>
                  setPasswordData({ ...passwordData, current: e.target.value })
                }
              />
            </LabelledInput>
            <LabelledInput
              label={t("scenes.config.tabs.userSettings.password.new")}
            >
              <TextField
                type="password"
                value={passwordData.new}
                onChange={(e) =>
                  setPasswordData({ ...passwordData, new: e.target.value })
                }
              />
            </LabelledInput>
            <LabelledInput
              label={t("scenes.config.tabs.userSettings.password.newRepeat")}
            >
              <TextField
                type="password"
                value={passwordData.newRepeat}
                onChange={(e) =>
                  setPasswordData({
                    ...passwordData,
                    newRepeat: e.target.value,
                  })
                }
              />
            </LabelledInput>
          </Box>
          <Box sx={{ ...styles.row, justifyContent: "flex-end" }}>
            <IconButton aria-label="save-password" onClick={saveNewPassword}>
              <Save
                sx={{ fontSize: "2em", color: theme.palette.success.main }}
              />
            </IconButton>
          </Box>
        </AccordionContent>
      </AccordionWrapper>
    </>
  );
};

export default UserSettings;
