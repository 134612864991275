import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ContactSupportOutlinedIcon from "@mui/icons-material/ContactSupportOutlined";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import InfoIconOutlined from "@mui/icons-material/InfoOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import Settings from "@mui/icons-material/Settings";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SidebarFooter,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { Language } from "@mui/icons-material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AuthContext } from "../../auth/AuthContext";
import kardioiqlogoblack from "../../resources/kardioiqlogoblack.png";
// import kardioiqlogoblack from "../../resources/hermiqslogoblack.png";
import kardioiqlogowhite from "../../resources/kardioiqlogowhite.png";
// import kardioiqlogowhite from "../../resources/hermiqslogowhite.png";
import { updateCurrentPage } from "../../state/sidebarSlice";

const Item = ({ title, to, icon, selected, setSelected, onClick }) => {
  const theme = useTheme();
  const location = useLocation();

  const isLink = to.startsWith("https://");

  const handleClick = () => {
    if (isLink) return;
    setSelected(title);
    onClick && onClick();
  };

  return (
    <MenuItem
      active={selected === title}
      style={{
        color:
          location.pathname === to
            ? theme.palette.primary.main
            : theme.palette.text.main,
      }}
      onClick={handleClick}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} target={isLink ? "_blank" : "_self"} />
    </MenuItem>
  );
};

const Sidebar = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { isCollapsed, setCollapsed } = props;

  function setIsCollapsed(value) {
    setCollapsed(value);
  }

  const dispatch = useDispatch();
  const { currentPage } = useSelector((state) => state.sidebar);

  const handleCurrentPage = (title) => {
    dispatch(updateCurrentPage(title));
  };

  const { logout } = useContext(AuthContext);

  return (
    <Box
      id="sidebar"
      sx={{
        "& .pro-sidebar-inner": {
          background: `${theme.palette.background.darker} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item:hover": {
          color: `${theme.palette.primary.main} !important`,
        },
        "& .pro-sidebar": {
          position: "fixed",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed ? "true" : undefined}>
        <SidebarContent style={{ flex: 12 }}>
          <Menu iconShape="square">
            <MenuItem
              onClick={() => setIsCollapsed(!isCollapsed)}
              icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
              style={{
                margin: `${theme.spacing(1)} 0 ${theme.spacing(2)}`,
                color: theme.palette.text.main,
              }}
            >
              {!isCollapsed && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  ml="15px"
                >
                  <Typography variant="h4" color={theme.palette.text.main}>
                    {/*RisKa*/}
                  </Typography>
                  <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                    {isCollapsed ? <MenuOutlinedIcon /> : <ChevronLeftIcon />}
                  </IconButton>
                </Box>
              )}
            </MenuItem>

            {!isCollapsed && (
              <Box mb="25px">
                <Box display="flex" justifyContent="center" alignItems="center">
                  <img
                    onClick={() => navigate("/analyse")}
                    alt="KardioIQ Logo"
                    src={
                      theme.palette.mode === "dark"
                        ? kardioiqlogowhite
                        : kardioiqlogoblack
                    }
                    width="130px"
                    style={{ cursor: "pointer" }}
                  />
                </Box>
              </Box>
            )}

            <Box paddingLeft={isCollapsed ? 0 : "10%"}>
              <Item
                title={t("sidebar.analysis")}
                to="/analyse"
                icon={<ContentPasteSearchIcon color="black" />}
                selected={currentPage}
                setSelected={handleCurrentPage}
              />

              <Item
                title={t("sidebar.about")}
                to="/about"
                icon={<InfoIconOutlined color="black" />}
                selected={currentPage}
                setSelected={handleCurrentPage}
              />
              <Item
                title={t("sidebar.faq")}
                to="/faq"
                icon={<ContactSupportOutlinedIcon color="black" />}
                selected={currentPage}
                setSelected={handleCurrentPage}
              />
            </Box>
          </Menu>
        </SidebarContent>

        <SidebarContent
          collapsed={isCollapsed ? "true" : undefined}
          style={{ flex: 1 }}
        >
          <Box paddingLeft={isCollapsed ? 0 : "10%"}>
            <Menu iconShape="square">
              <Item
                title={"Website"}
                to={"https://kardioiq.eu"}
                icon={<Language />}
              />
            </Menu>
          </Box>
        </SidebarContent>

        <SidebarFooter
          collapsed={isCollapsed ? "true" : undefined}
          style={{ flex: 1 }}
        >
          <Box paddingLeft={isCollapsed ? 0 : "10%"}>
            <Menu iconShape="square">
              <Item
                title={t("sidebar.settings")}
                to="/config"
                icon={<Settings color="black" />}
                selected={currentPage}
                setSelected={handleCurrentPage}
              />

              <Item
                title={t("sidebar.logout")}
                to="/"
                icon={<LogoutIcon color="black" />}
                selected={currentPage}
                setSelected={handleCurrentPage}
                onClick={() => {
                  logout();
                }}
              />
            </Menu>
          </Box>
        </SidebarFooter>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
