import { useTranslation } from "react-i18next";

export const kpiInformation = {
  avg_st_duration: {
    label: "Ø ST Dauer",
    unit: "ms",
    //TODO: Only dummy values!!
    male: {
      lowerUnhealthy: 75,
      upperUnhealthy: 125,
      lowerCritical: 50,
      upperCritical: 100,
    },
    female: {
      lowerUnhealthy: 75,
      upperUnhealthy: 125,
      lowerCritical: 50,
      upperCritical: 150,
    },
  },
  avg_qrs_duration: {
    label: "Ø QRS Dauer",
    unit: "ms",
    male: {
      upperUnhealthy: 100,
      upperCritical: 120,
    },
    female: {
      upperUnhealthy: 100,
      upperCritical: 120,
    },
  },
  avg_qt_duration: {
    label: "Ø QT Dauer",
    unit: "ms",
    male: {
      lowerUnhealthy: 350,
      upperUnhealthy: 440,
      upperCritical: 500,
    },
    female: {
      lowerUnhealthy: 350,
      upperUnhealthy: 460,
      upperCritical: 500,
    },
  },
  avg_pq_duration: {
    label: "Ø PQ Dauer",
    unit: "ms",
    male: {
      upperUnhealthy: 200,
      lowerCritical: 120,
    },
    female: {
      upperUnhealthy: 200,
      lowerCritical: 120,
    },
  },
  avg_rr_duration: {
    label: "Ø RR Dauer",
    unit: "ms",
    //TODO: Only dummy values!!
    male: {
      lowerUnhealthy: 75,
      upperUnhealthy: 125,
      lowerCritical: 50,
      upperCritical: 150,
    },
    female: {
      lowerUnhealthy: 75,
      upperUnhealthy: 125,
      lowerCritical: 50,
      upperCritical: 150,
    },
  },
  avg_heartrate: {
    label: "Ø Herzrate",
    unit: "bpm",
    male: {
      lowerUnhealthy: 50,
      upperUnhealthy: 100,
      lowerCritical: 35,
      upperCritical: 130,
    },
    female: {
      lowerUnhealthy: 50,
      upperUnhealthy: 100,
      lowerCritical: 35,
      upperCritical: 130,
    },
  },
  lagetyp: {
    label: "Lagetyp",
    types: {
      healthyTypes: ["linkstyp", "indifferenztyp"],
      unhealthyTypes: ["ueberdrehterlinkstyp", "steiltyp"],
      criticalTypes: ["rechtstyp", "ueberdrehterrechtstyp"],
    },
  },
  max_delta_pp_duration: {
    label: "Δ PP Zeit",
    unit: "ms",
    male: {
      lowerUnhealthy: 0,
      upperUnhealthy: 120,
    },
    female: {
      lowerUnhealthy: 0,
      upperUnhealthy: 120,
    },
  },
};

export const PathologyInformation = () => {
  const { t } = useTranslation();

  return {
    AFIB: {
      label: t("pathologies.AFIB"),
    },
    STEMI: {
      label: t("pathologies.STEMI"),
    },
    LBBB: {
      label: t("pathologies.LBBB"),
    },
  };
};
