import axios from "axios";
import { backend_uri } from "./config_reader";
import { updateAnalysisProgress } from "../state/patientSlice";

export const pollingState = {
  stopInterval: false,
  isRunning: false,
};

const predict = async (ecgs, patientData, abortController) => {
    const clinical_data = JSON.stringify({
      c_data: {
        patientId: patientData.patientId,
        age: patientData.age,
        sex: patientData.sex,
        risks: patientData.risks,
        recordingDuration: patientData.recordingDuration,
        ecgProfile: patientData.ecgProfile,
        job_specs: {
          outputs: [
            "AFIB_probability", // TODO: was passiert hier?
            "ISCH_probability",
            "LBBB_probability",
            "kpi_L_combined_property",
          ],
        },
      },
    });

    const formData = new FormData();
    formData.append("file", ecgs[0]);
    formData.append("data", clinical_data);

    try {
      const response = await axios.post(`${backend_uri}/api/predict/`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
        signal: abortController.signal,
      });

      return {'auth': response.data.auth, 'ticket': response.data.ticket, 'status': response.status};
    } catch (error) {
      return {'status': error.response.status}
    }
};

const result = async (auth, ticket, dispatch) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(`${backend_uri}/api/result/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
          "auth": auth,
          "ticket": ticket,
          "format": 'json'
        })
        .then((response) => {
          if (!response.data.Status) {
            pollingState.stopInterval = true;
            resolve(response.data);
          } else {
            const progress = response.data.Status;
            dispatch(updateAnalysisProgress(progress));
          }
        });
    } catch (error) {
      console.error("Error getting the result:", error);
      reject(error);
    }
  });
};

const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem("refresh");
  try {
    const response = await axios.post(`${backend_uri}/api/token/refresh/`, {
      refresh: refreshToken,
    });

    if (response.status === 200) {
      const newAccessToken = response.data.access;
      return newAccessToken;
    }
  } catch (error) {
    console.error("Failed to refresh access token:", error);
    throw error;
  }
};

const fetchUserSettings = async () => {
  try {
    const response = await axios.get(`${backend_uri}/api/user/settings/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    });
    return response;
  } catch (error) {
    console.error("Error fetching user settings:", error);
    return null;
  }
};

const changePassword = async (currentPassword, newPassword) => {
  try {
    const response = await axios.post(
      `${backend_uri}/api/user/password/`,
      { old_password: currentPassword, new_password: newPassword },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      }
    );

    return response;
  } catch (error) {
    console.error("Error changing password:", error);
  }
};

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const newAccessToken = await refreshAccessToken();
        localStorage.setItem("access", newAccessToken);
        originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
        return axios(originalRequest);
      } catch (refreshError) {
        console.error("Failed to retry request after token refresh:", error);
        throw refreshError;
      }
    }

    return Promise.reject(error);
  }
);

const DataTransfer = {
  refreshAccessToken,
  fetchUserSettings,
  predict,
  result,
  changePassword,
};

export default DataTransfer;
