import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  collapsed: false,
  currentPage: "EKG-Analyse",
};

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    collapse: (state, _) => {
      state.collapsed = !state.collapsed;
    },
    updateCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
});

export const { collapse, updateCurrentPage } = sidebarSlice.actions;

export default sidebarSlice.reducer;
