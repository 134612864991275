import { Print } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from "@mui/material";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  RadialLinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ECGController from "../../components/ECGController";
import { resetView } from "../../components/ECGViewer";
import Header from "../../components/Header";
import KpiGrid from "../../components/KpiGrid";
import config from "../../components/ecgViewer/config";
import { ColorModeContext } from "../../theme";
import { styles } from "../sharedStyles";
import PrintView from "./print";
import { setMessage, setSeverity } from "../../state/snackbarSlice";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title
);


const Result = () => {
  const location = useLocation();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { setMode } = useContext(ColorModeContext);

  const [unhealthyCount, setUnhealthyCount] = useState(0);
  const [criticalCount, setCriticalCount] = useState(0);

  const { t } = useTranslation();

  const {
    patientId,
    sex,
  } = useSelector((state) => state.patient);

  let kpiObjects = [];
  const pathologyProbabilities = [];
  const pathologyExplanations = {};

  let ecg = location.state["ecg"];
  let lead_index = location.state["lead_index"];
  let patient= location.state["patient_data"];
  let predictions = location.state["predictions"];
  let kpis = location.state["kpis"];

  for (const key in predictions){
    const pathologyProbability = {
        key: key,
        value: predictions[key]["probability"],
      };
    pathologyProbabilities.push(pathologyProbability);
  }

  for (const key in user.kpiOrder){
    let id = user.kpiOrder[key];
    let value = kpis[id];
    if(value !== undefined){
      const kpiObj = {
        key: id,
        value: value,
      };
      kpiObjects.push(kpiObj);
    }
  }

  for (const key in predictions){
    pathologyExplanations[key] = predictions[key]["explanation"];
  }

  const [currentExplanation, updateExplanation] = useState(
      pathologyProbabilities.sort((a, b) => b.value - a.value)[0].key
  );

  const ecgViewerData = { ecg, lead_index, ...pathologyExplanations };

  const theme = useTheme();

  const [printView, setPrintView] = useState(false);

  const [currentPathology, setCurrentPathology] = useState(
      pathologyProbabilities.sort((a, b) => b.value - a.value)[0].key
  );

  const [displayKpi, setDisplayKpi] = useState(false);

  const handleTabChange = (pathology) => {
    updateExplanation(pathology);
  };

  const handlePrint = () => {
    const currentTheme = user.themePreference;
    setMode("light");

    resetView({ zoomFactor: 1.4, x: -165, y: -100 });
    config.offsets.vertical = 2.5;
    setPrintView(true);
    setTimeout(() => {
      window.print();
      setPrintView(false);
      resetView({ zoomFactor: 1, x: 0, y: 0 });
      config.offsets.vertical = 4;
      setMode(currentTheme);
    }, 50);
  };

  return (
    <>
      <Box
        id="result-main-page"
        sx={{ ...styles(theme).mainContent, position: "relative" }}
      >
        <Button
          sx={{ position: "absolute", right: 0, marginRight: theme.spacing(2) }}
          color="neutral"
          onClick={handlePrint}
          startIcon={<Print />}
        >
          {t("ecgViewer.print")}
        </Button>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12} marginBottom={theme.spacing(4)}>
            <Header
              title={`Patient: ${patientId}`}
              subtitle={t("ecgViewer.subHeader")+":"}
            />
          </Grid>

          <Box display={"flex"} alignItems={"center"} width={"100%"}>
            <ToggleButtonGroup
              color="text"
              orientation="horizontal"
              value={currentPathology}
              onChange={(_, value) => {
                if (value !== null) {
                  setCurrentPathology(value);
                  handleTabChange(value);
                }
              }}
              sx={{ height: 50 }}
              fullWidth
              exclusive
            >
              {pathologyProbabilities
                .sort((a, b) => b.value - a.value)
                .map((probability, index) => (
                  <ToggleButton
                    key={index}
                    value={probability.key}
                    sx={{
                      textTransform: "none",
                      fontSize: theme.typography.h6.fontSize,
                      fontWeight: theme.typography.fontWeightBold,
                      color: theme.palette.text.dark,
                    }}
                  >
                    <Typography variant="h6" fontWeight={"bold"}>
                      {/*{`${t(`pathologies.${probability.key}`)} (${t(*/}
                      {/*  "misc.probability"*/}
                      {/*)}: ${probability.value.toFixed(1)}%)`}*/}
                      {`${t(`pathologies.${probability.key}`)} (${probability.value.toFixed(0)}%)`}
                    </Typography>
                  </ToggleButton>
                ))}
            </ToggleButtonGroup>
          </Box>

          <Box
            display={"grid"}
            gridTemplateAreas={"'controls controls' 'viewer kpi-grid'"}
            sx={{ transition: "grid-template-columns 0.25s linear" }}
            gridTemplateColumns={displayKpi ? "6fr 1fr" : "6fr 0fr"}
            width={"100%"}
            gap={theme.spacing(1)}
          >
            <ECGController
              data={ecgViewerData}
              currentExplanation={currentExplanation}
              ecgHeight={"90vh"}
              ecgWidth={"100%"}
              kpiDisplay={displayKpi}
              setKpiDisplay={setDisplayKpi}
              unhealthyCount={unhealthyCount}
              criticalCount={criticalCount}
            />
            <KpiGrid
              kpiObjects={kpiObjects}
              sx={{ gridArea: "kpi-grid" }}
              sex={sex}
              displayKpi={displayKpi}
              setUnhealthyCount={setUnhealthyCount}
              setCriticalCount={setCriticalCount}
            />
          </Box>
        </Grid>
      </Box>

      {printView && (
        <PrintView
          data={ecgViewerData}
          kpi={kpiObjects}
          pathologies={Object.keys(pathologyExplanations)}
          pathologyProbabilities={pathologyProbabilities}
          patientId={patientId}
          sex={sex}
        />
      )}
    </>
  );
};

export default Result;
