import { Undo } from "@mui/icons-material";
import {
  Box,
  IconButton,
  MenuItem,
  Select,
  styled,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { PresetColors } from "../data/colorTheme";
import { DefaultEcgColors } from "../scenes/sharedStyles";
import { changeEcgViewerColors, updateUserSettings } from "../state/userSlice";

const ColorSelect = styled(Select)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
}));

const ColorMenuItem = styled(MenuItem)(({ color }) => ({
  height: 50,
  "&:hover": {
    backgroundColor: `${color}80`,
  },
  "&.Mui-focusVisible, &.Mui-selected, &.Mui-selected:hover": {
    backgroundColor: `${color}20`,
  },
}));

/**
 * Component that lets users pick between a given palette of colors.
 *
 * @component
 * @example
 * return(
 *  <ColorPicker color={"#000"} setting={"grid"} />
 * )
 *
 * @param {string} color - The current selected color of the component.
 * @param {string} setting - The specific setting the color should be applied to.
 */
const ColorPicker = ({ color, setting }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const resetColor = () => {
    handleColorSelected(DefaultEcgColors()[setting], setting);
  };

  const handleChange = (event) => {
    handleColorSelected(event.target.value, setting);
  };

  const handleColorSelected = (color, target) => {
    dispatch(changeEcgViewerColors({ [target]: color }));
    dispatch(
      updateUserSettings({
        ecgViewerColors: { ...user.ecgViewerColors, [target]: color },
      })
    );
  };

  return (
    <Box display={"flex"} gap={theme.spacing(2)}>
      <ColorSelect sx={{ flex: 1 }} value={color} onChange={handleChange}>
        {Object.values(PresetColors()).map((color, index) => (
          <ColorMenuItem key={index} value={color.name} color={color.code}>
            <Box display={"flex"} alignItems={"center"}>
              <Box
                width={30}
                height={30}
                bgcolor={color.code}
                marginRight={theme.spacing(1)}
                borderRadius={theme.shape.borderRadius}
              />
              {t(`misc.colorPreset.${color.name}`)}
            </Box>
          </ColorMenuItem>
        ))}
      </ColorSelect>
      <IconButton onClick={resetColor}>
        <Undo />
      </IconButton>
    </Box>
  );
};

export default ColorPicker;
