import { Box, Tab, Tabs, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { styles } from "../sharedStyles";
import EcgProfileSettings from "./EcgProfileSettings";
import EcgViewerSettings from "./EcgViewerSettings";
import UserSettings from "./UserSettings";
import { useLocation } from "react-router-dom";

const TabPanel = ({ children, value, index }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`settings-tabpanel-${index}`}
      aria-labelledby={`settings-tabpanel-${index}`}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const tabPropsAtIndex = (index) => {
  return {
    id: `settings-tab-${index}`,
    "aria-controls": `settings-tab-${index}`,
  };
};

const Config = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const location = useLocation();

  const { t } = useTranslation();

  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (_, newTab) => {
    setCurrentTab(newTab);
  };

  useEffect(() => {
    if (!location.state) return;

    setCurrentTab(location.state.tabIndex);
  }, [location.state]);

  return (
    <>
      <Box sx={styles(theme).mainContent}>
        <Header title={t("scenes.config.heading")} />
        <Box
          sx={{
            marginTop: theme.spacing(2),
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <Tabs
            indicatorColor="text"
            value={currentTab}
            onChange={handleTabChange}
            variant="fullWidth"
            sx={{
              borderRadius: theme.shape.borderRadius,
              "& button:hover": { backgroundColor: colors.grey[900] },
              "& button.Mui-selected": {
                backgroundColor: theme.palette.background.dark,
                color: theme.palette.text.main,
              },
              "& .MuiTabs-flexContainer": {
                flexWrap: "wrap",
              },
            }}
          >
            <Tab
              sx={{
                textTransform: "none",
                fontSize: theme.typography.h6.fontSize,
                fontWeight: theme.typography.fontWeightBold,
                color: theme.palette.text.dark,
              }}
              label={t("scenes.config.tabs.userSettings.title")}
              {...tabPropsAtIndex(0)}
            />
            <Tab
              sx={{
                textTransform: "none",
                fontSize: theme.typography.h6.fontSize,
                fontWeight: theme.typography.fontWeightBold,
                color: theme.palette.text.dark,
              }}
              label={t("scenes.config.tabs.ecgViewer.title")}
              {...tabPropsAtIndex(1)}
            />
            <Tab
              sx={{
                textTransform: "none",
                fontSize: theme.typography.h6.fontSize,
                fontWeight: theme.typography.fontWeightBold,
                color: theme.palette.text.dark,
              }}
              label={t("scenes.config.tabs.ecgProfiles.title")}
              {...tabPropsAtIndex(2)}
            />
          </Tabs>
        </Box>
        <TabPanel value={currentTab} index={0}>
          <UserSettings />
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          <EcgViewerSettings />
        </TabPanel>
        <TabPanel value={currentTab} index={2}>
          <EcgProfileSettings />
        </TabPanel>
      </Box>
    </>
  );
};

export default Config;
