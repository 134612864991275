import { Close } from "@mui/icons-material";
import {
  Alert,
  Box,
  IconButton,
  Slide,
  Snackbar,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setMessage } from "../state/snackbarSlice";

const TransitionLeft = (props) => {
  return <Slide {...props} direction="left" />;
};

/**
 * App wide component to display snackbar messages
 * 
 * @component
 * @example
 * const snackbar = useSelector((state) => state.snackbar);
 * 
 * return (
 *  <CustomSnackbar message={snackbar.message} severity={snackbar.severity} />
 * )
 * 
 * @param {string} message - The message displayed in the snackbar.
 * @param {"primary" | "secondary" | "info" | "warning" | "error"} severity - The severity of the message, affects color of snackbar
 * @param {number} autoHideDuration - Duration after which the message hides itself. If "null" does not hide at all without the user dismissing it. 
 * @returns 
 */
const CustomSnackbar = ({
  message,
  severity = "info",
  autoHideDuration = 6000,
}) => {
  const dispatch = useDispatch();

  const [snackPack, setSnackPack] = useState([]);
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState(undefined);

  const position = {
    vertical: "bottom",
    horizontal: "right",
  };

  const { vertical, horizontal } = position;

  const handleClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    dispatch(setMessage(""));
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  useEffect(() => {
    if (message) {
      setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }]);
    }
  }, [message]);

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      setMessageInfo({ ...snackPack[0] });
      setOpen(true);
      setSnackPack((prev) => prev.slice(1));
    } else if (snackPack.length && messageInfo && open) {
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  useEffect(() => {
    return () => {
      setOpen(false);
      setMessageInfo(undefined);
      dispatch(setMessage(""));
    };
  }, [dispatch]);

  return (
    <Snackbar
      key={messageInfo ? messageInfo.key : undefined}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      TransitionProps={{ onExited: handleExited }}
      TransitionComponent={TransitionLeft}
      anchorOrigin={{ vertical, horizontal }}
      message={messageInfo ? messageInfo.message : undefined}
      action={
        <>
          <IconButton
            aria-label="close"
            color="inherit"
            sx={{ p: 0.5 }}
            onClick={handleClose}
          >
            <Close />
          </IconButton>
        </>
      }
    >
      <Alert
        severity={severity}
        onClose={handleClose}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={2}
        >
          <Typography variant="h4">{messageInfo?.message}</Typography>
        </Box>
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;
