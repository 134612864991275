import { useEffect } from "react";

import colors from "./ecgViewer/colors";
import config from "./ecgViewer/config";
import controller from "./ecgViewer/controller";
import eventHandlers from "./ecgViewer/eventhandler";
import viewer from "./ecgViewer/viewer";

function configColors(clr) {
  colors.grid = clr.grid;
  colors.gridCM = clr.grid;
  colors.highlight = clr.highlight;
  colors.ecg = clr.ecg;
  colors.measure = clr.measure;
  colors.timeline = clr.timeline;
  colors.visuals = clr.visuals;
}

function recalc() {
  const graphCount = Object.keys(config.globalProps.data.ecg).length;
  if (config.globalProps.singleColumn) {
    config.size.cols = 1;
    config.size.rows = graphCount;
  } else {
    config.size.cols = Math.floor(graphCount / 6);
    config.size.rows = Math.floor(graphCount / 2);
  }
  config.size.width = config.size.width + 15 * (config.size.cols - 1);

  config.scale.mmPerS = config.globalProps.speed;
  let fullLength = config.scale.mmPerS * (config.scale.duration / 1000);

  config.offsets.horizontal = Math.ceil(fullLength / 10) + 1;

  config.scale.ticksPerMm =
    config.globalProps.data.ecg["I"].length / fullLength;

  config.size.width = config.scale.duration / 10;
  config.size.height = config.globalProps.ecgHeight;
}

/**
 * The viewer containing all the ecg data.
 * 
 * @component
 * @example
 * <ECGViewer
    singleColumn={false}
    data={data}
    idAppendix={"appendToId"}
    currentExplanation={"I"}
    ecgWidth={"100%"}
    ecgHeight={"90vh"}
    leads={["I", "II", "III"]}
    speed={25}
    measure={"ruler"}
    displayed={["grid", "explainability", "markings"]}
    colors={{grid: "#000", ...}}
    font={ecgFont}
    snap={true}
    zoomCallback={() => {}}
    translations={t}
  />
 * 
 * @param {boolean} singleColumn - Whether or not the ecg leads should be displayed in a single column. If false, its displayed in two columns.
 * @param {{}} data - The passed ecg data from the ecgController. 
 * @param {string} idAppendix - String that should be appended to the canvas id in order to have unique ids. Introduced because of print view.
 * @param {string} currentExplanation - Lead that should currently display the explanation hightlights.
 * @param {string} ecgWidth - The width of the ecg canvas.
 * @param {string} ecgHeight - The height of the ecg canvas.
 * @param {[string]} leads - The array of strings, which leads should be displayed in the viewer.
 * @param {number} speed - The current speed the viewer should display the leads in.
 * @param {"ruler" | "measure" | ""} measure - Defines, which measurement tool should be active currently.  
 * @param {[string]} displayed - Defines, which elements should be displayed in the viewer. Includes grid, timeline and explainabilities.
 * @param {{}} colors - The defined colors for the ecgViewer.
 * @param {string} font - The font that the ecg viewer uses.
 * @param {boolean} snap - Whether or not the measurement tools should snap to the graph.
 * @param {CallableFunction} zoomCallback - Function that should be called when the reset zoom button is pressed.
 * @param {CallableFunction} translations - i18n t() function, to enable translations inside of the viewer.
 * @returns 
 */
function ECGViewer(props) {
  if (!props.data.reducedECG) {
    let tolerance = 1;
    let reducedECG = {};
    for (let l in props.data.ecg) {
      let lead = props.data.ecg[l];
      reducedECG[l] = [];
      for (let i = 0; i < lead.length - 1; i++) {
        let value = lead[i];
        if (i < 1) {
          reducedECG[l].push({ x: i, y: value });
        } else {
          let prevSlope = value - reducedECG[l][reducedECG[l].length - 1].y;
          let nextSlope = lead[i + 1] - value;
          if (
            prevSlope / nextSlope > 1 + tolerance ||
            prevSlope / nextSlope < 1 - tolerance
          ) {
            reducedECG[l].push({ x: i, y: value });
          }
        }
      }
    }
    props.data.reducedECG = reducedECG;
  }

  configColors(props.colors);
  config.globalProps = props;

  recalc();

  useEffect(() => {
    //Initialize elements on first drawing
    config.canvas.element = document.getElementById(
      "ecggrid" + config.globalProps.idAppendix
    );
    config.canvas.element.addEventListener(
      "wheel",
      (e) => {
        e.preventDefault();
      },
      {
        passive: false,
      }
    );

    config.canvas.resizeObserver = new ResizeObserver((x) => {
      config.canvas.grid.width = Math.floor(
        config.canvas.element.offsetWidth * 2
      );
      config.canvas.graph.width = Math.floor(
        config.canvas.element.offsetWidth * 2
      );
      config.canvas.highlight.width = Math.floor(
        config.canvas.element.offsetWidth * 2
      );
      config.canvas.measure.width = Math.floor(
        config.canvas.element.offsetWidth * 2
      );
      config.canvas.marking.width = Math.floor(
        config.canvas.element.offsetWidth * 2
      );
      config.canvas.grid.height = Math.floor(
        config.canvas.element.offsetHeight * 2
      );
      config.canvas.graph.height = Math.floor(
        config.canvas.element.offsetHeight * 2
      );
      config.canvas.highlight.height = Math.floor(
        config.canvas.element.offsetHeight * 2
      );
      config.canvas.measure.height = Math.floor(
        config.canvas.element.offsetHeight * 2
      );
      config.canvas.marking.height = Math.floor(
        config.canvas.element.offsetHeight * 2
      );
      viewer.draw();
    });

    config.canvas.resizeObserver.observe(config.canvas.element);

    config.canvas.grid = document.getElementById(
      "ecggrid" + config.globalProps.idAppendix
    );
    config.canvas.highlight = document.getElementById(
      "ecghighlight" + config.globalProps.idAppendix
    );
    config.canvas.graph = document.getElementById(
      "ecggraph" + config.globalProps.idAppendix
    );
    config.canvas.measure = document.getElementById(
      "ecgmeasure" + config.globalProps.idAppendix
    );
    config.canvas.marking = document.getElementById(
      "ecgmarking" + config.globalProps.idAppendix
    );
    document
      .getElementById("ecgmeasure" + config.globalProps.idAppendix)
      .addEventListener("wheel", eventHandlers.canvas.wheel, {
        passive: false,
      });

    config.canvas.grid.width = Math.floor(
      config.canvas.element.offsetWidth * 2
    );
    config.canvas.graph.width = Math.floor(
      config.canvas.element.offsetWidth * 2
    );
    config.canvas.highlight.width = Math.floor(
      config.canvas.element.offsetWidth * 2
    );
    config.canvas.measure.width = Math.floor(
      config.canvas.element.offsetWidth * 2
    );
    config.canvas.marking.width = Math.floor(
      config.canvas.element.offsetWidth * 2
    );
    config.canvas.grid.height = Math.floor(
      config.canvas.element.offsetHeight * 2
    );
    config.canvas.graph.height = Math.floor(
      config.canvas.element.offsetHeight * 2
    );
    config.canvas.highlight.height = Math.floor(
      config.canvas.element.offsetHeight * 2
    );
    config.canvas.measure.height = Math.floor(
      config.canvas.element.offsetHeight * 2
    );
    config.canvas.marking.height = Math.floor(
      config.canvas.element.offsetHeight * 2
    );
    resetView({ zoomFactor: 1, x: 0, y: 0 });
  }, []);

  useEffect(() => {
    config.globalProps = props;
    recalc();
    viewer.draw();
  }, [props]);

  //let [ecgW, setEcgW] = useState(config.canvas.element?config.canvas.element.offsetWidth*2:0);//2 * config.size.width * config.size.cols;
  //let [ecgH, setEcgH] = useState(config.canvas.element?config.canvas.element.offsetHeight*2:0);//(config.size.rows*config.offsets.vertical + config.offsets.top + config.offsets.bottom)*config.time.cmscale;//2 * config.size.height * config.size.rows;

  //config.size.setter = {ecgW, setEcgW, setEcgH};
  let ecgW = 0;
  let ecgH = 0;

  return (
    <>
      <div
        id={"ecgviewer" + props.idAppendix}
        className="ecgviewer"
        style={{
          width: props.ecgWidth,
          height: props.ecgHeight,
          cursor: props.measure === "ruler" ? "none" : "inherit",
        }}
      >
        <canvas
          id={"ecggrid" + props.idAppendix}
          width={ecgW}
          height={ecgH}
        ></canvas>
        <canvas
          id={"ecghighlight" + props.idAppendix}
          width={ecgW}
          height={ecgH}
        ></canvas>
        <canvas
          id={"ecggraph" + props.idAppendix}
          width={ecgW}
          height={ecgH}
        ></canvas>
        <canvas
          id={"ecgmarking" + props.idAppendix}
          width={ecgW}
          height={ecgH}
        ></canvas>
        <canvas
          id={"ecgmeasure" + props.idAppendix}
          width={ecgW}
          height={ecgH}
          onMouseOver={eventHandlers.canvas.mouseover}
          onMouseMove={eventHandlers.canvas.mousemove}
          onClick={eventHandlers.canvas.click}
          onMouseLeave={eventHandlers.canvas.mouseleave}
          onMouseDown={eventHandlers.canvas.mousedown}
          onMouseUp={eventHandlers.canvas.mouseup}
        ></canvas>
      </div>
    </>
  );
}

const handleZoom = controller.handleZoom;
const redraw = () => {
  viewer.draw();
};
const getZoom = () => {
  return Math.round(config.movement.zoomFactor * 100) + "%";
};
const resetView = ({ zoomFactor = 1, x = 0, y = 0 }) => {
  config.movement.x = x;
  config.movement.y = y;
  config.movement.zoomFactor = zoomFactor;
  config.globalProps.zoomCallback();
};

export { ECGViewer, getZoom, handleZoom, redraw, resetView };
