import styled from "@emotion/styled";
import { Dangerous, ReportProblem } from "@mui/icons-material";
import { Grid, Paper, Typography, useTheme } from "@mui/material";
import { kpiInformation } from "../data/resultLookUps";

const Item = styled(Paper)(({ theme, color }) => ({
  backgroundColor: "transparent",
  border: `3px solid ${color}`,
  ...theme.typography.body2,
  padding: theme.spacing(2),
  margin: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.main,
  flexGrow: 1,
  borderRadius: theme.shape.borderRadius,
  boxShadow: "none",
}));

/**
 * KPI Grid variant for the print view.
 *
 * @component
 * @example
 * return (
 *  <KpiGridPrint
 *    kpiObjects={kpiObjects}
 *    sx={{ margin: "auto" }}
 *    sex={"other"}
 *  />
 * )
 *
 * @param {{}} kpiObjects - The KPI objects to be displayed.
 * @param {{}} sx - Material style object to apply custom styles.
 * @param {"male" | "female" | "other"} sex - The sex of the patient. Important for medical thresholds.
 * @returns
 */
const KpiGridPrint = ({ kpiObjects, sx, sex }) => {
  const theme = useTheme();

  const itemStyle = (value, thresholds, types) => {
    const getColorAndIcon = (color, icon) => ({ color, icon });

    if (types) {
      const typeMappings = [
        {
          types: types.healthyTypes,
          color: theme.palette.success.main,
          icon: null,
        },
        {
          types: types.unhealthyTypes,
          color: theme.palette.warning.main,
          icon: (
            <ReportProblem
              sx={{
                color: theme.palette.warning.main,
                fontSize: theme.typography.h2.fontSize,
              }}
            />
          ),
        },
        {
          types: types.criticalTypes,
          color: theme.palette.error.main,
          icon: (
            <Dangerous
              sx={{
                color: theme.palette.error.main,
                fontSize: theme.typography.h2.fontSize,
              }}
            />
          ),
        },
      ];

      for (const mapping of typeMappings) {
        if (mapping.types.includes(value))
          return getColorAndIcon(mapping.color, mapping.icon);
      }
    }

    const criticalThresholds = [
      thresholds.lowerCritical,
      thresholds.upperCritical,
    ];
    const unhealthyThresholds = [
      thresholds.lowerUnhealthy,
      thresholds.upperUnhealthy,
    ];

    if (value < criticalThresholds[0] || value >= criticalThresholds[1])
      return getColorAndIcon(
        theme.palette.error.main,
        <Dangerous
          sx={{
            color: theme.palette.error.main,
            fontSize: theme.typography.h2.fontSize,
          }}
        />
      );
    if (value < unhealthyThresholds[0] || value >= unhealthyThresholds[1])
      return getColorAndIcon(
        theme.palette.warning.main,
        <ReportProblem
          sx={{
            color: theme.palette.warning.main,
            fontSize: theme.typography.h2.fontSize,
          }}
        />
      );

    return getColorAndIcon(theme.palette.success.main, null);
  };

  const objectDimensionInRow = 12 / kpiObjects.length;

  return (
    <Grid container spacing={0} justifyContent={"center"} sx={sx}>
      {kpiObjects.map((kpi, index) => {
        //TODO: If the kpi is unknown it will not be considered whatsoever
        if (!kpiInformation[kpi.key]) return <></>;
        return (
          <Grid
            key={index}
            item
            xs={(12 / kpiObjects.length) * 3}
            md={objectDimensionInRow * 2}
            xl={objectDimensionInRow}
          >
            <Item
              color={
                itemStyle(
                  kpi.value,
                  !sex || sex === "other"
                    ? kpiInformation[kpi.key]["male"]
                    : kpiInformation[kpi.key][sex.toString()],
                  kpiInformation[kpi.key].types
                ).color
              }
            >
              <Typography variant="p">
                {kpiInformation[kpi.key].label}
              </Typography>
              <Typography variant="h4" fontWeight={"bold"}>
                {kpi.value} {kpiInformation[kpi.key].unit}
              </Typography>
            </Item>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default KpiGridPrint;
