import { Box, Typography, useTheme } from "@mui/material";

/**
 * Displaying a header and optional subheader
 *
 * @component
 * @example
 * return(
 *  <Header title={"Main Title"} subtitle={"Sub Title"} />
 * )
 *
 * @param {string} title - The large main title of the header.
 * @param {string} subtitle - The small sub title of the header.
 */
const Header = ({ title, subtitle }) => {
  const theme = useTheme();
  return (
    <Box textAlign={"center"}>
      <Typography
        variant="h3"
        color={theme.palette.text.main}
        fontWeight="bold"
        sx={{ marginBottom: theme.spacing(2) }}
      >
        {title}
      </Typography>
      <Typography variant="h4" color={theme.palette.text.light}>
        {subtitle}
      </Typography>
    </Box>
  );
};

export default Header;
