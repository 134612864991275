import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import kardioiqlogoblack from "../../resources/kardioiqlogoblack.png";
// import kardioiqlogoblack from "../../resources/hermiqslogoblack.png";
import kardioiqlogowhite from "../../resources/kardioiqlogowhite.png";
// import kardioiqlogowhite from "../../resources/hermiqslogowhite.png";
import { styles } from "../sharedStyles";

const BottomBar = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box id="bottombar" sx={styles(theme).mainContent}>
      <Box display="flex" justifyContent="center" alignItems="center">
        <img
          onClick={() => navigate("/analyse")}
          alt="KardioIQ Logo"
          src={
            theme.palette.mode === "dark"
              ? kardioiqlogowhite
              : kardioiqlogoblack
          }
          width="100px"
          style={{ cursor: "pointer" }}
        />
      </Box>
    </Box>
  );
};
export default BottomBar;
