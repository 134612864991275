import { DeleteForever, Save } from "@mui/icons-material";
import {
  Box,
  IconButton,
  MenuItem,
  Select,
  TextField,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { styles } from "../../scenes/Config/settingStyles";
import { LabelledInput } from "./LabelledInputs";

/**
 * A profile that can be added and selected on the analysis page.
 * 
 * @component
 * @example
 * const [profiles, setProfiles] = useState([])
 * 
 * const deleteFromProfileList = () => {}
 * const saveProfileChanges = () => {}
 * 
 * return (
 *  <>
  *  {profiles.map((profile) => (
  *    <EcgProfile 
  *      index={profile.id}
  *      profileTitle={"Title"}
  *      selectedManufacturer={"schiller"}
  *      deleteFromProfileList={deleteFromProfileList}
  *      saveProfileChanges={saveProfileChanges}
  *    />
  *  ))}
 *  </>
 * )
 * 
 * @param {number} index - Unique ID for the profile. 
 * @param {string} profileTitle - The rendered title of the profile.
 * @param {"ge" | "schiller"} selectedManufacturer - The ecg device manufacturer.
 * @param {CallableFunction} deleteFromProfileList - Callback function that gets called when delete is pressed.
 * @param {CallableFunction} saveProfileChanges - Callback function that gets called when save if pressed.
 */
const EcgProfile = ({
  index,
  profileTitle,
  selectedManufacturer,
  deleteFromProfileList,
  saveProfileChanges,
}) => {
  const theme = useTheme();

  const { t } = useTranslation();

  const [profileName, setProfileName] = useState(profileTitle);
  const [manufacturer, setManufacturer] = useState(selectedManufacturer);

  const manufacturers = [
    { name: "schiller", label: "Schiller" },
    { name: "ge", label: "GE" },
  ];

  const handleManufacturerChange = (event) => {
    setManufacturer(event.target.value);
  };

  const handleDeleteProfile = () => {
    deleteFromProfileList(index);
  };

  const handleSaveProfile = () => {
    saveProfileChanges(index, profileName, manufacturer);
  };

  return (
    <>
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <LabelledInput
          label={t(
            "scenes.config.tabs.ecgProfiles.addProfile.labels.profileName"
          )}
        >
          <TextField
            value={profileName}
            onChange={(e) => setProfileName(e.target.value)}
          />
        </LabelledInput>
        <LabelledInput
          label={t(
            "scenes.config.tabs.ecgProfiles.addProfile.labels.manufacturer"
          )}
        >
          <Select
            id="manufacturer-select-label"
            value={manufacturer}
            onChange={handleManufacturerChange}
          >
            {manufacturers.map((manufacturer, index) => (
              <MenuItem key={index} value={manufacturer.name}>
                {manufacturer.label}
              </MenuItem>
            ))}
          </Select>
        </LabelledInput>
      </Box>
      <Box sx={{ ...styles.row, justifyContent: "flex-end" }}>
        <IconButton aria-label="delete-profile" onClick={handleDeleteProfile}>
          <DeleteForever
            sx={{ fontSize: "2em", color: theme.palette.primary.main }}
          />
        </IconButton>
        <IconButton aria-label="save-profile" onClick={handleSaveProfile}>
          <Save sx={{ fontSize: "2em", color: theme.palette.success.main }} />
        </IconButton>
      </Box>
    </>
  );
};

export default EcgProfile;
