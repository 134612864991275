import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import CustomSnackbar from "../../components/Snackbar";
import BottomBar from "./BottomBar";
import Sidebar from "./Sidebar";
import TopBar from "./Topbar";

const Layout = () => {
  const snackbar = useSelector((state) => state.snackbar);
  const theme = useTheme();

  const [isSidebar, setIsSidebar] = useState(false);
  const isNonMobile = useMediaQuery("(min-width: 600px)");

  return (
    <Box display={isNonMobile ? "flex" : "flex"} width="100%" height="100%">
      <Sidebar isCollapsed={isSidebar} setCollapsed={setIsSidebar} />

      <CustomSnackbar message={snackbar.message} severity={snackbar.severity} />

      <Box
        className="main-content-container"
        component="main"
        sx={{
          flexGrow: 1,
          paddingLeft: "1%",
          paddingRight: "1%",
          marginLeft: isSidebar ? theme.spacing(8) : theme.spacing(32),
          transition: theme.transitions.create(["margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }}
      >
        <TopBar />
        <Outlet />
        <BottomBar />
      </Box>
    </Box>
  );
};

export default Layout;
