import { Dangerous, ReportProblem } from "@mui/icons-material";
import { Box, Paper, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useCallback, useEffect } from "react";
import { kpiInformation } from "../data/resultLookUps";

const Item = styled(Paper)(({ theme, color }) => ({
  backgroundColor: "transparent",
  backgroundImage: "none",
  border: `3px solid ${color}`,
  ...theme.typography.body2,
  padding: theme.spacing(2),
  margin: theme.spacing(0),
  textAlign: "center",
  flexGrow: 1,
  borderRadius: theme.shape.borderRadius,
  boxShadow: "none",
}));

/**
 * Grid that displays detected KPIs in the result page.
 *
 * @component
 * @example
 * const [displayKpi, setDisplayKpi] = useState(false);
 * const [unhealthyCount, setUnhealthyCount] = useState(0);
 * const [criticalCount, setCriticalCount] = useState(0);
 *
 * return (
 *  <KpiGrid
 *    kpiObjects={objects}
 *    sx={{ display: "block" }}
 *    sex={"other"}
 *    displayKpi={displayKpi}
 *    setUnhealthyCount={setUnhealthyCount}
 *    setCriticalCount={setCriticalCount}
 *  />
 * )
 *
 * @param {{}} kpiObjects - The information for the KPI objects.
 * @param {{}} sx - Material style object to define custom styles.
 * @param {"male" | "female" | "other"} sex - The sex of the patient. Important for medical thresholds.
 * @param {boolean} displayKpi - Whether or not the KPIs should be expanded.
 * @param {CallableFunction} setUnhealthyCount - React state setter to set the count of unhealthy conditions
 * @param {CallableFunction} setCriticalCount - React state setter to set the count of critical conditions
 * @returns
 */
const KpiGrid = ({
  kpiObjects,
  sx,
  sex,
  displayKpi,
  setUnhealthyCount,
  setCriticalCount,
}) => {
  const theme = useTheme();

  const itemStyle = useCallback((value, thresholds, types) => {
    const getColorAndIcon = (color, icon) => ({ color, icon });

    if (types) {
      const typeMappings = [
        {
          types: types.healthyTypes,
          color: theme.palette.success.main,
          icon: null,
        },
        {
          types: types.unhealthyTypes,
          color: theme.palette.warning.main,
          icon: (
            <ReportProblem
              sx={{
                color: theme.palette.warning.main,
                fontSize: theme.typography.h2.fontSize,
              }}
            />
          ),
        },
        {
          types: types.criticalTypes,
          color: theme.palette.error.main,
          icon: (
            <Dangerous
              sx={{
                color: theme.palette.error.main,
                fontSize: theme.typography.h2.fontSize,
              }}
            />
          ),
        },
      ];

      for (const mapping of typeMappings) {
        if (mapping.types.includes(value))
          return getColorAndIcon(mapping.color, mapping.icon);
      }
    }

    const criticalThresholds = [
      thresholds.lowerCritical,
      thresholds.upperCritical,
    ];
    const unhealthyThresholds = [
      thresholds.lowerUnhealthy,
      thresholds.upperUnhealthy,
    ];

    if (value < criticalThresholds[0] || value >= criticalThresholds[1])
      return getColorAndIcon(
        theme.palette.error.main,
        <Dangerous
          sx={{
            color: theme.palette.error.main,
            fontSize: theme.typography.h2.fontSize,
          }}
        />
      );
    if (value < unhealthyThresholds[0] || value >= unhealthyThresholds[1])
      return getColorAndIcon(
        theme.palette.warning.main,
        <ReportProblem
          sx={{
            color: theme.palette.warning.main,
            fontSize: theme.typography.h2.fontSize,
          }}
        />
      );

    return getColorAndIcon(theme.palette.success.main, null);
  }, [theme]);

  useEffect(() => {
    let newUnhealthyCount = 0;
    let newCriticalCount = 0;

    kpiObjects.forEach((kpi) => {
      const { color } = itemStyle(
        kpi.value,
        !sex || sex === "other"
          ? kpiInformation[kpi.key]["male"]
          : kpiInformation[kpi.key][sex.toString()],
        kpiInformation[kpi.key].types
      );

      if (color === theme.palette.warning.main) {
        newUnhealthyCount += 1;
      } else if (color === theme.palette.error.main) {
        newCriticalCount += 1;
      }
    });

    setUnhealthyCount(newUnhealthyCount);
    setCriticalCount(newCriticalCount);
  }, [
    kpiObjects,
    sex,
    theme.palette.warning.main,
    theme.palette.error.main,
    itemStyle,
    setCriticalCount,
    setUnhealthyCount,
  ]);

  return (
    <Box
      sx={{
        ...sx,
        transition:
          "border 0.25s linear, padding 0.25s linear, width 0.25s linear",
      }}
      width={displayKpi ? "100%" : "0%"}
      padding={displayKpi ? theme.spacing(2) : theme.spacing(0)}
      border={displayKpi ? `2px solid ${theme.palette.text.main}` : "none"}
      borderRadius={theme.shape.borderRadius}
      maxHeight={"90vh"}
      overflow={"scroll"}
    >
      {kpiObjects.map((kpi, index) => (
        <Box key={index} sx={{ position: "relative" }}>
          {/* TODO: selecting no gender defaults to male atm! */}
          <Item
            sx={{
              transition: "margin 0.25s linear",
              marginY: displayKpi ? theme.spacing(1) : theme.spacing(0),
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
            color={
              itemStyle(
                kpi.value,
                !sex || sex === "other"
                  ? kpiInformation[kpi.key]["male"]
                  : kpiInformation[kpi.key][sex.toString()],
                kpiInformation[kpi.key].types
              ).color
            }
          >
            <Typography variant="p">{kpiInformation[kpi.key].label}</Typography>
            <Typography variant="h4" fontWeight={"bold"}>
              {kpi.value} {kpiInformation[kpi.key].unit}
            </Typography>
            <Box
              sx={{
                position: "absolute",
                right: -6,
                top: -5,
                backgroundColor: theme.palette.background.darker,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {
                itemStyle(
                  kpi.value,
                  !sex || sex === "other"
                    ? kpiInformation[kpi.key]["male"]
                    : kpiInformation[kpi.key][sex.toString()],
                  kpiInformation[kpi.key].types
                ).icon
              }
            </Box>
          </Item>
        </Box>
      ))}
    </Box>
   );
};

export default KpiGrid;
