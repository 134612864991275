import { useTheme } from "@mui/material";
import { tokens } from "../theme";

export const PresetColors = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return {
    grey: {
      name: "grey",
      code: theme.palette.neutral.dark,
      textColor: theme.palette.mode === "light" ? "#000" : "#fff",
    },
    neutral: {
      name: "neutral",
      code: theme.palette.neutral.light,
      textColor: theme.palette.mode === "light" ? "#fff" : "#000",
    },
    red: { name: "red", code: theme.palette.primary.main, textColor: "#000" },
    green: {
      name: "green",
      code: theme.palette.success.main,
      textColor: "#000",
    },
    blue: { name: "blue", code: colors.blueAccent[500], textColor: "#000" },
    yellow: {
      name: "yellow",
      code: colors.yellowAccent[500],
      textColor: "#000",
    },
  };
};

export const ColorThemePresets = () => {
  return {
    default: {
      value: "default",
      colors: {
        grid: PresetColors().grey.name,
        highlight: PresetColors().red.name,
        ecg: PresetColors().neutral.name,
        measure: PresetColors().green.name,
        timeline: PresetColors().neutral.name,
        visuals: PresetColors().neutral.name,
      },
    },
    red_green_support: {
      value: "red_green_support",
      colors: {
        grid: PresetColors().grey.name,
        highlight: PresetColors().yellow.name,
        ecg: PresetColors().neutral.name,
        measure: PresetColors().blue.name,
        timeline: PresetColors().neutral.name,
        visuals: PresetColors().neutral.name,
      },
    },
  };
};
