import config from "./config";

const controller = {
  /**
   * selects the node closest to the pointer
   * @param {MouseEvent} event
   */
  markClosestNode: (event) => {
    const borderTop = config.offsets.top;
    const borderLeft = config.offsets.left;
    const columnOffset = config.offsets.horizontal;
    const rowOffset = config.offsets.vertical;

    let positionX =
      (event.nativeEvent.offsetX *
        (config.canvas.element.width / config.canvas.element.offsetWidth)) /
        config.movement.zoomFactor -
      config.movement.x;
    positionX -= borderLeft * config.scale.cmscale;
    let activeColumn;
    // let graphWidth =
    //   (((config.scale.mmPerS / 10) * config.scale.duration) / 1000) *
    //   config.scale.cmscale;
    if (
      positionX < 0 ||
      positionX >
        (borderLeft + columnOffset * config.size.cols) * config.scale.cmscale
    ) {
      activeColumn = -1;
    } else {
      activeColumn = Math.floor(
        positionX / (columnOffset * config.scale.cmscale)
      );
    }

    let positionY =
      (event.nativeEvent.offsetY *
        (config.canvas.element.height / config.canvas.element.offsetHeight)) /
        config.movement.zoomFactor -
      config.movement.y;
    positionY -= borderTop * config.scale.cmscaleHeight;
    let activeRow;
    let graphHeight = rowOffset * config.scale.cmscaleHeight;
    if (
      positionY < 0 ||
      positionY > graphHeight * config.size.rows + rowOffset * config.size.rows
    ) {
      activeRow = -1;
    } else {
      activeRow = Math.floor(positionY / graphHeight);
    }

    config.measurement.activeGraph = {
      column: activeColumn,
      row: activeRow,
    };

    let closestNode =
      positionX - columnOffset * activeColumn * config.scale.cmscale;

    let result = Math.round(
      closestNode > 0 ? closestNode / config.scale.tickFactor : -1
    );

    const data =
      config.globalProps.data.ecg[
        config.globalProps.leads[activeColumn * config.size.rows + activeRow]
      ];

    if (!data) return;

    if (event.shiftKey || config.globalProps.snap === "top") {
      while (
        data[result] < data[result + 1] ||
        data[result] < data[result - 1]
      ) {
        result++;
      }
    }
    if (event.nativeEvent.ctrlKey || config.globalProps.snap === "bottom") {
      while (
        data[result] > data[result + 1] ||
        data[result] > data[result - 1]
      ) {
        result++;
      }
    }

    config.measurement.closestNode = result;
  },

  /**
   * sets the memorized node to the current closest node
   */
  setMeasure: () => {
    if (config.measurement.memNode) {
      config.measurement.measure.push({
        from: config.measurement.memNode,
        to: config.measurement.closestNode,
        graph: {
          column: config.measurement.activeGraph.column,
          row: config.measurement.activeGraph.row,
        },
      });
      config.measurement.memNode = null;
    } else {
      config.measurement.memNode = config.measurement.closestNode;
    }
  },

  setMarkings: () => {
    if (config.measurement.memNode) {
      config.marking.push({
        from: Math.min(
          config.measurement.closestNode,
          config.measurement.memNode
        ),
        to: Math.max(
          config.measurement.memNode,
          config.measurement.closestNode
        ),
        graph: {
          column: config.measurement.activeGraph.column,
          row: config.measurement.activeGraph.row,
        },
      });
      config.measurement.memNode = null;
    } else {
      config.measurement.memNode = config.measurement.closestNode;
    }
  },

  /**
   * zooms in or out depending on the deltaY property of the passed event
   * @param {WheelEvent} event®
   */
  handleZoom: (event) => {
    if (event.deltaY < 0) {
      if (config.movement.zoomFactor > 4.9) {
        config.movement.zoomFactor = 5;
      } else {
        config.movement.x -=
          (event.offsetX *
            (0.1 / config.movement.zoomFactor) *
            (config.canvas.element.width / config.canvas.element.offsetWidth)) /
          config.movement.zoomFactor;
        config.movement.y -=
          (event.offsetY *
            (0.1 / config.movement.zoomFactor) *
            (config.canvas.element.width / config.canvas.element.offsetWidth)) /
          config.movement.zoomFactor;

        config.movement.zoomFactor += 0.1;
      }
    } else {
      if (config.movement.zoomFactor < 0.6) {
        config.movement.zoomFactor = 0.5;
      } else {
        config.movement.x +=
          (event.offsetX *
            (0.1 / config.movement.zoomFactor) *
            (config.canvas.element.width / config.canvas.element.offsetWidth)) /
          config.movement.zoomFactor;
        config.movement.y +=
          (event.offsetY *
            (0.1 / config.movement.zoomFactor) *
            (config.canvas.element.width / config.canvas.element.offsetWidth)) /
          config.movement.zoomFactor;

        config.movement.zoomFactor -= 0.1;
      }
    }

    controller.enforceBoundaries();
    config.globalProps.zoomCallback();
  },

  /**
   * moves the viewport depending on the amount of movement detected by the passed event
   * @param {MouseEvent} event
   */
  handleMove: (event) => {
    config.movement.x +=
      (event.movementX *
        (config.canvas.element.width / config.canvas.element.offsetWidth)) /
      config.movement.zoomFactor;
    config.movement.y +=
      (event.movementY *
        (config.canvas.element.width / config.canvas.element.offsetWidth)) /
      config.movement.zoomFactor;
    config.movement.changed = true;

    controller.enforceBoundaries();
  },

  enforceBoundaries: () => {
    //Width of the graphic in cm
    const graphicWidth =
      (config.offsets.left +
        config.offsets.horizontal * config.size.cols +
        config.offsets.right) *
      config.scale.cmscale;
    //Height of the graphic in cm
    const graphicHeight =
      (config.offsets.top +
        config.offsets.vertical * config.size.rows +
        config.offsets.bottom) *
      config.scale.cmscaleHeight;

    //Width of the viewport
    const viewportHeight =
      config.canvas.element.offsetHeight *
      (config.canvas.element.height / config.canvas.element.offsetHeight);
    //Height of the viewport
    const viewportWidth =
      config.canvas.element.offsetWidth *
      (config.canvas.element.width / config.canvas.element.offsetWidth);

    if (
      config.movement.x <
      viewportWidth / config.movement.zoomFactor - graphicWidth
    ) {
      config.movement.x =
        viewportWidth / config.movement.zoomFactor - graphicWidth;
    }

    if (
      config.movement.y <
      viewportHeight / config.movement.zoomFactor - graphicHeight
    ) {
      config.movement.y =
        viewportHeight / config.movement.zoomFactor - graphicHeight;
    }

    if (config.movement.x > 0) {
      config.movement.x = 0;
    }

    if (config.movement.y > 0) {
      config.movement.y = 0;
    }
  },
};

export default controller;
