import SortableList from "../../SortableList";

/**
 * Settings panel for kpi customization.
 *
 * @component
 * @example
 *
 * return (‚
 *  <KpiCustomizationPanel />
 * )
 *
 * @param {[]} kpiArray - Array of all available kpis.
 */
const KpiCustomizationPanel = ({ kpiArray }) => {
  return (
    <>
      <SortableList items={kpiArray} />
    </>
  );
};

export default KpiCustomizationPanel;
