import colors from "./colors";
import config from "./config";
import util from "./util";

const viewer = {
  /**
   * Draws millimeter grid as background
   * @param {CanvasRenderingContext2D} ctx canvas context to draw on
   */
  drawGrid: (ctx) => {
    //Width of the graphic in cm
    const graphicWidth = Math.max(
      config.offsets.left +
        config.offsets.horizontal * config.size.cols +
        config.offsets.right,
      config.canvas.element.offsetWidth /
        (config.canvas.cmscale / 100) /
        config.movement.zoomFactor
    );
    //Height of the graphic in cm
    const graphicHeight = Math.max(
      config.offsets.top +
        config.offsets.vertical * config.size.rows +
        config.offsets.bottom,
      config.canvas.element.offsetHeight /
        (config.canvas.cmscale / 100) /
        config.movement.zoomFactor
    );
    ctx.strokeStyle = colors.grid;

    //Vertical Lines
    let cmTracker = -1;
    for (let offset = 0; offset < graphicWidth; offset += 0.1) {
      cmTracker++;
      ctx.beginPath();
      if (cmTracker === 10) {
        //Thick lines at cm bounds
        ctx.lineWidth = 3;
        cmTracker = 0;
        ctx.strokeStyle = colors.gridCM;
      } else {
        ctx.lineWidth = 1;
        ctx.strokeStyle = colors.grid;
      }
      util.moveTo(ctx, offset * config.scale.cmscale, 0);
      util.lineTo(
        ctx,
        offset * config.scale.cmscale,
        graphicHeight * config.scale.cmscaleHeight
      );
      ctx.stroke();
    }

    //Horizontal Lines
    cmTracker = -1;
    for (let offset = 0; offset < graphicHeight; offset += 0.1) {
      cmTracker++;
      ctx.beginPath();
      if (cmTracker === 10) {
        //Thick lines at cm bounds
        ctx.lineWidth = 3;
        cmTracker = 0;
        ctx.strokeStyle = colors.gridCM;
      } else {
        ctx.lineWidth = 1;
        ctx.strokeStyle = colors.grid;
      }
      util.moveTo(ctx, 0, offset * config.scale.cmscaleHeight);
      util.lineTo(
        ctx,
        graphicWidth * config.scale.cmscale,
        offset * config.scale.cmscaleHeight
      );
      ctx.stroke();
    }

    //"Eichzacke"
    ctx.beginPath();
    ctx.strokeStyle = colors.visuals;
    ctx.lineWidth = 3;
    let baseOffset = config.scale.cmscale * 3;
    let vertOffset =
      (config.offsets.top + config.offsets.vertical / 2) * config.scale.cmscale;
    util.moveTo(ctx, baseOffset + config.scale.cmscale * -1, vertOffset);
    util.lineTo(ctx, baseOffset + config.scale.cmscale * -1.2, vertOffset);
    util.lineTo(
      ctx,
      baseOffset + config.scale.cmscale * -1.2,
      vertOffset - config.scale.cmscaleHeight
    );
    util.lineTo(
      ctx,
      baseOffset + config.scale.cmscale * -1.8,
      vertOffset - config.scale.cmscaleHeight
    );
    util.lineTo(ctx, baseOffset + config.scale.cmscale * -1.8, vertOffset);
    util.lineTo(ctx, baseOffset + config.scale.cmscale * -2, vertOffset);
    ctx.stroke();

    //duration
    let tInSec =
      `${config.globalProps.translations("ecgViewer.duration")}:` +
      Math.round(config.scale.duration / 1000) +
      "s";
    ctx.beginPath();
    ctx.font =
      config.globalProps.font.fontSize +
      "px " +
      config.globalProps.font.fontFamily;
    ctx.fillStyle = colors.visuals;
    util.text(
      ctx,
      tInSec,
      -config.movement.x + 100 / config.movement.zoomFactor,
      -config.movement.y +
        config.globalProps.font.fontSize / config.movement.zoomFactor
    );
    ctx.stroke();
  },

  /**
   * draws visual elements, currently just text
   * @param {CanvasRenderingContext2D} ctx canvas context to draw on
   * @param {string} text text to write
   * @param {number} column column of the graph; if -1, skip scaling
   * @param {number} row row of the graph; if -1, skip scaling
   */
  drawVisuals: (ctx, text, column, row) => {
    ctx.beginPath();
    //let textCoord = { x: 14, y: 1500 };
    let textCoord = {
      x: -150 * (config.globalProps.font.fontSize / config.scale.mmPerS),
      y: 0,
    };
    //ctx.font = 25 * config.movement.zoomFactor + "px sans-serif";
    ctx.font =
      config.globalProps.font.fontSize +
      "px " +
      config.globalProps.font.fontFamily;
    ctx.beginPath();
    ctx.fillStyle = colors.visuals;
    util.text(ctx, text, textCoord.x, textCoord.y, column, row);
    ctx.fill();
  },

  /**
   * draws a timeline for each ecg
   * @param {CanvasRenderingContext2D} ctx canvas context to draw on
   * @param {number} column column of the graph; if -1, skip scaling
   * @param {number} row row of the graph; if -1, skip scaling
   */
  drawTimeline: (ctx, column, row) => {
    //Timeline
    ctx.beginPath();
    ctx.strokeStyle = colors.timeline;
    ctx.fillStyle = colors.timeline;
    ctx.font =
      config.globalProps.font.fontSize +
      "px " +
      config.globalProps.font.fontFamily;
    ctx.lineWidth = 3;
    util.moveTo(ctx, 0, 0, column, row);
    util.lineTo(
      ctx,
      ((((config.scale.mmPerS / 10) * config.scale.duration) / 1000) *
        config.scale.cmscale) /
        config.scale.tickFactor,
      0,
      column,
      row
    );
    for (let i = 0; i <= config.scale.duration / 1000; i++) {
      util.moveTo(
        ctx,
        ((config.scale.mmPerS / 10) * i * config.scale.cmscale) /
          config.scale.tickFactor,
        -500,
        column,
        row
      );
      util.lineTo(
        ctx,
        ((config.scale.mmPerS / 10) * i * config.scale.cmscale) /
          config.scale.tickFactor,
        500,
        column,
        row
      );
      util.text(
        ctx,
        i + "s",
        ((config.scale.mmPerS / 10) * i * config.scale.cmscale) /
          config.scale.tickFactor,
        -500,
        column,
        row
      );
    }
    ctx.stroke();
  },

  /**
   * draws the ecg graph based on the passed data
   * @param {CanvasRenderingContext2D} ctx canvas context to draw on
   * @param {[]} data array of coordinates; index = x coordinate, value = y coordinate
   * @param {number} column column of the graph; if -1, skip scaling
   * @param {number} row row of the graph; if -1, skip scaling
   */
  drawECG: (ctx, data, column, row) => {
    ctx.beginPath();
    ctx.strokeStyle = colors.ecg;

    util.moveTo(ctx, 0, 0, column, row);
    for (let d = 0; d < data.length; d = d + 1) {
      let point = data[d];
      if (config.globalProps.displayed.indexOf("explainability") !== -1) {
        if (
          point.x >=
          config.globalProps.data[config.globalProps.currentExplanation].I
            .length
        ) {
          break;
        }
      }
      //util.lineTo(ctx, d, data[d], column, row);
      util.lineTo(ctx, point.x, point.y, column, row);
    }
    ctx.lineWidth = 3;
    //ctx.strokeStyle = "black";
    ctx.stroke();
  },

  /**
   * draws highlighting on the ecg based on the passed explainability
   * @param {CanvasRenderingContext2D} ctx canvas context to draw on
   * @param {[]} data array of coordinates; index = x coordinate, value = y coordinate
   * @param {[]} explainability array of intensity; index = x coordinate, value = intensity
   * @param {number} column column of the graph; if -1, skip scaling
   * @param {number} row row of the graph; if -1, skip scaling
   */
  drawExplainability: (ctx, data, explainability, column, row) => {
    ctx.beginPath();
    for (let hl in explainability) {
      if (explainability[hl] === 0) continue;
      //Circle Highlights
      util.circle(ctx, hl, data[hl], column, row, 5 + 5 * explainability[hl]);
      ctx.lineWidth = 1;
      ctx.fillStyle = util.hexToRgba(colors.highlight, explainability[hl]);
      ctx.fill();

      //Backgroud Highlight
      // ctx.beginPath();
      //       ctx.strokeStyle = "rgba(255,0,0," + explainability[hl] + ")";
      //       ctx.lineWidth = 1;
      //       util.moveTo(ctx, hl, -1000, column, row);
      //       util.lineTo(ctx, hl, 1000, column, row);
      //       ctx.stroke();
      ctx.beginPath();
    }
  },

  drawMarkings: (ctx, data, column, row) => {
    if (!data) return;
    if (
      column === config.measurement.activeGraph.column &&
      row === config.measurement.activeGraph.row
    ) {
      ctx.beginPath();
      //Draw closest node marking
      util.circle(
        ctx,
        config.measurement.closestNode,
        data[config.measurement.closestNode],
        column,
        row,
        15
      );
      ctx.fill();
      ctx.beginPath();
    }

    if (
      column === config.measurement.activeGraph.column &&
      row === config.measurement.activeGraph.row
    ) {
      ctx.beginPath();
      util.moveTo(
        ctx,
        Math.min(config.measurement.closestNode, config.measurement.memNode),
        data[
          Math.min(config.measurement.closestNode, config.measurement.memNode)
        ],
        column,
        row
      );
      if (config.measurement.memNode) {
        for (
          let i = Math.min(
            config.measurement.closestNode,
            config.measurement.memNode
          );
          i <
          Math.max(config.measurement.memNode, config.measurement.closestNode);
          i++
        ) {
          util.lineTo(ctx, i, data[i], column, row);
        }
        ctx.stroke();
        ctx.beginPath();
      }
    }
  },

  drawStoredMarkings: (ctx, data, column, row) => {
    for (const marking of config.marking) {
      if (marking.graph.column === column && marking.graph.row === row) {
        util.moveTo(ctx, marking.from, data[marking.from], column, row);
        for (let i = marking.from; i < marking.to; i++) {
          util.lineTo(ctx, i, data[i], column, row);
        }
        ctx.stroke();
        ctx.beginPath();
      }
    }
  },

  /**
   * draws the current digital measurement markings
   * @param {CanvasRenderingContext2D} ctx canvas context to draw on
   * @param {[]} data array of coordinates; index = x coordinate, value = y coordinate
   * @param {number} column column of the graph; if -1, skip scaling
   * @param {number} row row of the graph; if -1, skip scaling
   * @returns
   */
  drawMeasure: (ctx, data, column, row) => {
    if (!data) return;

    const color = util.hexToRgba(colors.measure, 0.8); //"rgba(0,255,0,0.5)";
    ctx.lineWidth = 3;
    ctx.strokeStyle = color;
    ctx.fillStyle = color;

    //if(config.measurement.closestNode === -1) return;
    if (
      column === config.measurement.activeGraph.column &&
      row === config.measurement.activeGraph.row
    ) {
      ctx.beginPath();
      //Draw closest node marking
      util.circle(
        ctx,
        config.measurement.closestNode,
        data[config.measurement.closestNode],
        column,
        row,
        10
      );
      ctx.fill();
      ctx.beginPath();
      util.circle(
        ctx,
        config.measurement.memNode,
        data[config.measurement.memNode],
        column,
        row,
        10
      );
      ctx.fill();
      ctx.beginPath();

      //Voltage Label
      ctx.font =
        config.globalProps.font.fontSize +
        "px " +
        config.globalProps.font.fontFamily;
      ctx.fillStyle = colors.visuals;

      let voltage = Math.round(data[config.measurement.closestNode]) / 1000;
      voltage += "mV";
      util.text(
        ctx,
        voltage,
        config.measurement.closestNode + config.globalProps.font.fontSize,
        data[config.measurement.closestNode] + 50,
        column,
        row
      );
      ctx.beginPath();
    }

    for (let measure of config.measurement.measure) {
      ctx.fillStyle = color;
      if (column !== measure.graph.column || row !== measure.graph.row)
        continue;
      //Draw from circle
      util.circle(
        ctx,
        measure.from,
        data[measure.from],
        measure.graph.column,
        measure.graph.row,
        10
      );
      ctx.fill();
      ctx.beginPath();
      //Draw to circle
      util.circle(
        ctx,
        measure.to,
        data[measure.to],
        measure.graph.column,
        measure.graph.row,
        10
      );
      ctx.fill();
      ctx.beginPath();
      //Connect the circles
      util.moveTo(
        ctx,
        measure.from,
        data[measure.from],
        measure.graph.column,
        measure.graph.row
      );
      util.lineTo(
        ctx,
        measure.to,
        data[measure.from],
        measure.graph.column,
        measure.graph.row
      );
      util.lineTo(
        ctx,
        measure.to,
        data[measure.to],
        measure.graph.column,
        measure.graph.row
      );
      ctx.stroke();
      ctx.beginPath();

      //Time label
      let deltaTime = Math.abs(measure.from - measure.to);
      deltaTime = deltaTime > 0 ? deltaTime : -deltaTime;
      deltaTime *= config.scale.duration / data.length;
      deltaTime = Math.round(deltaTime);
      deltaTime += "ms";
      ctx.fillStyle = colors.visuals;
      util.text(
        ctx,
        deltaTime,
        (measure.from + measure.to) / 2,
        Math.max(...data) + 500,
        measure.graph.column,
        measure.graph.row
      );
      ctx.beginPath();

      //Voltage Label
      let deltaVoltage = Math.abs((data[measure.from] - data[measure.to]) / 1000);
      deltaVoltage += "mV";
      util.text(
        ctx,
        deltaVoltage,
        (measure.from + measure.to) / 2,
        Math.max(...data) + config.globalProps.font.fontSize * 5,
        measure.graph.column,
        measure.graph.row
      );
      ctx.beginPath();
    }
  },

  drawRuler: (ctx, angle = 0, data) => {
    if (!config.movement.lastMoveEvent) return;
    const factorX = 2;
    const factorY = 2;
    const rulerLength = 15;
    let x;
    let y;

    if (config.globalProps.snap && data) {
      let coords = util.getCurrentCoordinates(
        config.measurement.closestNode,
        data[config.measurement.closestNode],
        config.measurement.activeGraph.column,
        config.measurement.activeGraph.row
      );
      x = coords.x / config.movement.zoomFactor - config.movement.x;
      y = coords.y / config.movement.zoomFactor - config.movement.y;
    } else {
      x =
        (config.movement.lastMoveEvent.nativeEvent.offsetX * factorX) /
          config.movement.zoomFactor -
        config.movement.x;
      y =
        (config.movement.lastMoveEvent.nativeEvent.offsetY * factorY) /
          config.movement.zoomFactor -
        config.movement.y;
    }

    ctx.beginPath();
    ctx.strokeStyle = colors.visuals;

    const xShiftL = config.scale.cmscale * 0.3;
    const xShiftR = config.scale.cmscale * (rulerLength + 0.3);
    const yShift = config.scale.cmscaleHeight * 1;

    util.moveTo(
      ctx,
      x - xShiftL * Math.cos(angle),
      y - xShiftL * Math.sin(angle)
    );
    util.lineTo(
      ctx,
      x + xShiftR * Math.cos(angle),
      y +
        xShiftR *
          Math.sin(angle) *
          (config.scale.cmscaleHeight / config.scale.cmscale)
    );
    util.lineTo(
      ctx,
      x + xShiftR * Math.cos(angle) - yShift * Math.sin(angle),
      y +
        (xShiftR * Math.sin(angle) + yShift * Math.cos(angle)) *
          (config.scale.cmscaleHeight / config.scale.cmscale)
    );
    util.lineTo(
      ctx,
      x - xShiftL * Math.cos(angle) - yShift * Math.sin(angle),
      y +
        (yShift * Math.cos(angle) - xShiftL * Math.sin(angle)) *
          (config.scale.cmscaleHeight / config.scale.cmscale)
    );
    util.lineTo(
      ctx,
      x - xShiftL * Math.cos(angle),
      y -
        xShiftL *
          Math.sin(angle) *
          (config.scale.cmscaleHeight / config.scale.cmscale)
    );

    ctx.stroke();
    ctx.fillStyle = util.hexToRgba(colors.measure, 0.22); //"rgba(0,255,0,0.01)";
    ctx.fill();
    for (let i = 0; i <= rulerLength; i++) {
      ctx.beginPath();
      ctx.lineWidth = 2;
      ctx.font =
        config.globalProps.font.fontSize * config.movement.zoomFactor +
        "px " +
        config.globalProps.font.fontFamily;
      ctx.strokeStyle = colors.visuals;
      ctx.fillStyle = colors.visuals;
      util.moveTo(
        ctx,
        x + i * config.scale.cmscale * Math.cos(angle),
        y + i * config.scale.cmscaleHeight * Math.sin(angle)
      );
      util.lineTo(
        ctx,
        x +
          i * config.scale.cmscale * Math.cos(angle) -
          0.6 * config.scale.cmscale * Math.sin(angle),
        y +
          0.6 * config.scale.cmscaleHeight * Math.cos(angle) +
          i * config.scale.cmscaleHeight * Math.sin(angle)
      );
      ctx.stroke();
      ctx.beginPath();
      let textMeasure = ctx.measureText(i);
      let xShift = i * config.scale.cmscale;
      let yShift = 0.6 * config.scale.cmscaleHeight;
      util.text(
        ctx,
        i,
        x + xShift * Math.cos(angle) - yShift * Math.sin(angle),
        y +
          yShift * Math.cos(angle) +
          xShift *
            Math.sin(angle) *
            (config.scale.cmscaleHeight / config.scale.cmscale) +
          (textMeasure.fontBoundingBoxAscent / config.movement.zoomFactor) *
            Math.abs(Math.cos(angle / 2) ** 2)
      );
    }
    for (let i = 0.5; i <= rulerLength; i++) {
      ctx.beginPath();
      ctx.lineWidth = 1;
      util.moveTo(
        ctx,
        x + i * config.scale.cmscale * Math.cos(angle),
        y + i * config.scale.cmscaleHeight * Math.sin(angle)
      );
      util.lineTo(
        ctx,
        x +
          i * config.scale.cmscale * Math.cos(angle) -
          0.4 * config.scale.cmscale * Math.sin(angle),
        y +
          0.4 * config.scale.cmscaleHeight * Math.cos(angle) +
          i * config.scale.cmscaleHeight * Math.sin(angle)
      );
      ctx.stroke();
    }
    for (let i = 0.1; i < rulerLength; i += 0.1) {
      ctx.beginPath();
      ctx.lineWidth = 1;
      util.moveTo(
        ctx,
        x + i * config.scale.cmscale * Math.cos(angle),
        y + i * config.scale.cmscaleHeight * Math.sin(angle)
      );
      util.lineTo(
        ctx,
        x +
          i * config.scale.cmscale * Math.cos(angle) -
          0.2 * config.scale.cmscale * Math.sin(angle),
        y +
          0.2 * config.scale.cmscaleHeight * Math.cos(angle) +
          i * config.scale.cmscaleHeight * Math.sin(angle)
      );
      ctx.stroke();
    }
  },

  /**
   * draws the full graphic
   */
  draw: () => {
    //Initialize Scaling
    config.canvas.cmscale = document.getElementById("cmscale").offsetWidth * 1.5; // TODO: quickfix only -> make this adaptive based on ECG length?
    config.scale.cmscale =
      (config.canvas.cmscale / 100) *
      (config.canvas.element.width / config.canvas.element.offsetWidth);
    config.scale.cmscaleHeight =
      (config.canvas.cmscale / 100) *
      (config.canvas.element.height / config.canvas.element.offsetHeight);

    if (config.globalProps.singleColumn) {
      let pxPerCm = config.scale.cmscaleHeight;
      let px = config.canvas.element.offsetHeight;
      let offsetInCm = config.offsets.bottom + config.offsets.top;
      config.offsets.vertical =
        ((px *
          (config.canvas.element.height / config.canvas.element.offsetHeight)) /
          pxPerCm -
          offsetInCm) /
        12;
    } else {
      config.offsets.vertical = 3; //TODO: Only quick fix. Find better calculation for the vertical offset based on space thats left?
    }

    config.scale.tickFactor =
      config.scale.cmscale / (config.scale.ticksPerMm * 10);

    //Clear all canvases
    config.canvas.grid
      .getContext("2d")
      .clearRect(
        0,
        0,
        config.canvas.element.width,
        config.canvas.element.height
      );
    config.canvas.highlight
      .getContext("2d")
      .clearRect(
        0,
        0,
        config.canvas.element.width,
        config.canvas.element.height
      );
    config.canvas.graph
      .getContext("2d")
      .clearRect(
        0,
        0,
        config.canvas.element.width,
        config.canvas.element.height
      );
    config.canvas.measure
      .getContext("2d")
      .clearRect(
        0,
        0,
        config.canvas.element.width,
        config.canvas.element.height
      );
    config.canvas.marking
      .getContext("2d")
      .clearRect(
        0,
        0,
        config.canvas.element.width,
        config.canvas.element.height
      );

    //Check for grid toggle
    if (config.globalProps.displayed.indexOf("grid") !== -1)
      viewer.drawGrid(config.canvas.grid.getContext("2d"), "", 0, 0);

    let i = 0;
    //Draw all leads
    for (let li in config.globalProps.data.lead_index) {
      let lead = config.globalProps.data.lead_index[li]
      if (config.globalProps.leads.indexOf(lead) === -1) continue;
      let column = Math.floor(i / config.size.rows) % config.size.cols;
      let row = i % config.size.rows;

      //Check for explainability toggle
      if (config.globalProps.displayed.indexOf("explainability") !== -1)
        viewer.drawExplainability(
          config.canvas.highlight.getContext("2d"),
          config.globalProps.data.ecg[lead],
          config.globalProps.data[config.globalProps.currentExplanation][lead],
          column,
          row
        );

      //Draw visuals
      viewer.drawVisuals(
        config.canvas.graph.getContext("2d"),
        lead,
        column,
        row
      );

      //Draw timelines
      if (config.globalProps.displayed.indexOf("timeline") !== -1)
        viewer.drawTimeline(config.canvas.graph.getContext("2d"), column, row);

      //draw lead
      viewer.drawECG(
        config.canvas.graph.getContext("2d"),
        config.globalProps.data.reducedECG[lead],
        column,
        row
      );
      i++;
    }

    //Draw user markings
    if (config.globalProps.displayed.indexOf("markings") !== -1)
      viewer.redrawMarkings(config.canvas.marking.getContext("2d"));
    viewer.redrawMeasure(config.canvas.measure.getContext("2d"));
  },

  /**
   * redraws the markings without touching the other canvases
   */
  redrawMarkings: (ctx) => {
    const color = util.hexToRgba(colors.highlight, 0.85);
    ctx.lineWidth = 8;
    ctx.strokeStyle = color;
    ctx.fillStyle = color;

    config.canvas.marking
      .getContext("2d")
      .clearRect(
        0,
        0,
        config.canvas.element.width,
        config.canvas.element.height
      );

    let i = 0;
    for (let lead in config.globalProps.data.ecg) {
      viewer.drawStoredMarkings(
        config.canvas.marking.getContext("2d"),
        config.globalProps.data.ecg[lead],
        Math.floor(i / config.size.rows),
        i % config.size.rows
      );
      if (config.globalProps.mark) {
        viewer.drawMarkings(
          config.canvas.marking.getContext("2d"),
          config.globalProps.data.ecg[lead],
          Math.floor(i / config.size.rows),
          i % config.size.rows
        );
      }
      i++;
    }
  },

  redrawMeasure: (ctx) => {
    const color = util.hexToRgba(colors.measure, 0.8); //"rgba(0,255,0,0.5)";
    ctx.lineWidth = 3;
    ctx.strokeStyle = color;
    ctx.fillStyle = color;

    config.canvas.measure
      .getContext("2d")
      .clearRect(
        0,
        0,
        config.canvas.element.width,
        config.canvas.element.height
      );

    switch (config.globalProps.measure) {
      case "digital":
        let i = 0;
        for (let lead in config.globalProps.data.ecg) {
          viewer.drawMeasure(
            config.canvas.measure.getContext("2d"),
            config.globalProps.data.ecg[lead],
            Math.floor(i / config.size.rows),
            i % config.size.rows
          );
          i++;
        }
        break;
      case "ruler":
        viewer.drawRuler(
          config.canvas.measure.getContext("2d"),
          config.ruler.rulerAngle,
          config.globalProps.data.ecg[
            Object.keys(config.globalProps.data.ecg)[
              config.measurement.activeGraph.column * config.size.rows +
                config.measurement.activeGraph.row
            ]
          ]
        );
        break;
      default:
        break;
    }
  },
};

export default viewer;
