import config from "./config";

const util = {
  /**
   * transforms absolute coordinates into relative coordinates
   * @param {CanvasRenderingContext2D} ctx canvas context to draw on
   * @param {number} x absolute x coordinate
   * @param {number} y absolute y coordinate
   * @param {number} column column of the graph; if -1, skip scaling
   * @param {number} row row of the graph; if -1, skip scaling
   */
  getCurrentCoordinates: (x, y, column = -1, row = -1) => {
    if (column !== -1 && row !== -1) {
      const borderTop = config.offsets.top;
      const borderLeft = config.offsets.left;
      const columnOffset = column * config.offsets.horizontal;
      const rowOffset = row * config.offsets.vertical;
      const offsetX = (borderLeft + columnOffset) * config.scale.cmscale;
      const offsetY = (borderTop + rowOffset) * config.scale.cmscaleHeight;
      const scaledX = x * config.scale.tickFactor;
      const scaledY = ((1500 - y) / 1000) * config.scale.cmscaleHeight;
      return {
        x: (offsetX + scaledX + config.movement.x) * config.movement.zoomFactor,
        y: (offsetY + scaledY + config.movement.y) * config.movement.zoomFactor,
      };
    } else {
      return {
        x: (x + config.movement.x) * config.movement.zoomFactor,
        y: (y + config.movement.y) * config.movement.zoomFactor,
      };
    }
  },

  hexToRgba: (hex, alpha) => {
    let values = hex
      .split("#")[1]
      .split("")
      .map((n) => parseInt(n, 16));
    return `rgba(${values[0] * 16 + values[1]},${values[2] * 16 + values[3]},${
      values[4] * 16 + values[5]
    },${alpha})`;
  },

  /**
   * utility function for relative canvas moveTo
   * @param {CanvasRenderingContext2D} ctx canvas context to draw on
   * @param {number} x absolute x coordinate
   * @param {number} y absolute y coordinate
   * @param {number} column column of the graph; if -1, skip scaling
   * @param {number} row row of the graph; if -1, skip scaling
   */
  moveTo: (ctx, x, y, column = -1, row = -1) => {
    const coords = util.getCurrentCoordinates(x, y, column, row);
    ctx.moveTo(coords.x, coords.y);
  },

  /**
   * utility function for relative canvas lineTo
   * @param {CanvasRenderingContext2D} ctx canvas context to draw on
   * @param {number} x absolute x coordinate
   * @param {number} y absolute y coordinate
   * @param {number} column column of the graph; if -1, skip scaling
   * @param {number} row row of the graph; if -1, skip scaling
   */
  lineTo: (ctx, x, y, column = -1, row = -1) => {
    const coords = util.getCurrentCoordinates(x, y, column, row);
    ctx.lineTo(coords.x, coords.y);
  },

  /**
   * utility function for relative canvas arc with a full circle
   * @param {CanvasRenderingContext2D} ctx canvas context to draw on
   * @param {number} x absolute x coordinate
   * @param {number} y absolute y coordinate
   * @param {number} column column of the graph; if -1, skip scaling
   * @param {number} row row of the graph; if -1, skip scaling
   * @param {number} radius radius of the circle
   */
  circle: (ctx, x, y, column = -1, row = -1, radius) => {
    const coords = util.getCurrentCoordinates(x, y, column, row);
    ctx.arc(coords.x, coords.y, radius, 0, 2 * Math.PI);
  },

  /**
   * utility function for relative canvas moveTo
   * @param {CanvasRenderingContext2D} ctx canvas context to draw on
   * @param {string} text text to write
   * @param {number} x absolute x coordinate
   * @param {number} y absolute y coordinate
   * @param {number} column column of the graph; if -1, skip scaling
   * @param {number} row row of the graph; if -1, skip scaling
   */
  text: (ctx, text, x, y, column = -1, row = -1) => {
    // let sideRelation =
    //   config.canvas.element.offsetWidth / config.canvas.element.offsetHeight;
    //alert(sideRelation)
    ctx.fontStretch = "ultra-condensed";
    /*if(sideRelation > 1){
            ctx.fontStretch = "ultra-condensed";
        } else if(sideRelation > 0.8){
            ctx.fontStretch = "extra-condensed";
        } else if(sideRelation > 0.6){
            ctx.fontStretch = "condensed";
        }*/
    let textMeasure = ctx.measureText(text);
    const coords = util.getCurrentCoordinates(x, y, column, row);
    ctx.fillText(text, coords.x - textMeasure.width / 2, coords.y);
  },
};

export default util;
