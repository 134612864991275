import { Add } from "@mui/icons-material";
import { Box, Fab, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AccordionContent, AccordionWrapper } from "../../components/Accordion";
import EcgProfile from "../../components/Config/EcgProfile";
import { setMessage, setSeverity } from "../../state/snackbarSlice";
import { changeEcgProfiles, updateUserSettings } from "../../state/userSlice";
import { styles } from "./settingStyles";

const EcgProfileSettings = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [profileList, setProfileList] = useState(user.ecgProfiles);
  const [expanded, setExpanded] = useState(false);

  const deleteFromProfileList = (profileIndex) => {
    const profileTitle = profileList[profileIndex].profileTitle;
    const editedItem = profileList.filter((_, index) => index !== profileIndex);
    setProfileList(editedItem);
    dispatch(changeEcgProfiles(editedItem));
    dispatch(updateUserSettings({ ecgProfiles: editedItem }));

    dispatch(
      setMessage(
        t("snackbarMessages.ecgProfileDeleted", {
          profile: profileTitle,
        })
      )
    );
    dispatch(setSeverity("warning"));
  };

  const addToProfileList = () => {
    const randomNumber = Math.random() * 1000;
    const timestamp = new Date().getTime();

    const newProfileId = `profile-${timestamp}-${randomNumber}`;
    setProfileList([
      ...profileList,
      {
        id: newProfileId,
        profileTitle: t(
          "scenes.config.tabs.ecgProfiles.addProfile.defaultName"
        ),
        selectedManufacturer: "",
      },
    ]);
    setExpanded(newProfileId);
  };

  const saveProfileChanges = (
    profileIndex,
    newProfileName,
    newSelectedManufacturer
  ) => {
    let currentProfileList = [...profileList];
    let editedItem = { ...currentProfileList[profileIndex] };
    editedItem = {
      ...currentProfileList[profileIndex],
      profileTitle: newProfileName,
      selectedManufacturer: newSelectedManufacturer,
    };

    currentProfileList[profileIndex] = editedItem;

    if (editedItem.selectedManufacturer) {
      setProfileList(currentProfileList);

      dispatch(changeEcgProfiles(currentProfileList));
      dispatch(updateUserSettings({ ecgProfiles: currentProfileList }));
      setExpanded(false);

      dispatch(
        setMessage(
          t("snackbarMessages.ecgProfileChanged", {
            profile: editedItem.profileTitle,
          })
        )
      );
      dispatch(setSeverity("success"));
    } else {
      dispatch(setMessage(t("snackbarMessages.ecgProfileNoManufacturer")));
      dispatch(setSeverity("error"));
    }
  };

  const handleChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      {profileList.length === 0 && (
        <Typography variant="h6" sx={{ fontStyle: "italic" }}>
          {t("scenes.config.tabs.ecgProfiles.noneAvailable")}
        </Typography>
      )}
      <AccordionWrapper>
        {profileList.map((profile, index) => (
          <AccordionContent
            key={index}
            header={profile.profileTitle}
            headerFontWeight="bold"
            panel={profile.id}
            handleChange={handleChange}
            expanded={expanded}
          >
            <EcgProfile
              index={index}
              profileTitle={profile.profileTitle}
              selectedManufacturer={profile.selectedManufacturer}
              deleteFromProfileList={deleteFromProfileList}
              saveProfileChanges={saveProfileChanges}
            />
          </AccordionContent>
        ))}
      </AccordionWrapper>
      <Box sx={{ ...styles.row, justifyContent: "flex-end" }}>
        <Fab color="primary" aria-label="add" onClick={addToProfileList}>
          <Add />
        </Fab>
      </Box>
    </>
  );
};

export default EcgProfileSettings;
