import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import React, { useEffect, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: theme.shape.borderRadius,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor:
      theme.palette.mode === "light" ? "rgba(255, 99, 132, 0.5)" : "#308fe8",
  },
}));

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <BorderLinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.light">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

/**
 * Lets users choose or drag in files they want to upload.
 *
 * @component
 * @example
 * const [reset, setReset] = useState(false);
 * const [uploadedFiles, setUploadedFiles] = useState([]);
 *
 * return (
 *  <AdaptedDropzone
 *    reset={reset}
 *    label={"Dropzone"}
 *    uploadedFiles={uploadedFiles}
 *    setUploadedFiles={setUploadedFiles}
 *  />
 * )
 *
 * @param {boolean} reset - When true resets all uploaded files.
 * @param {string} label - Text, that gets displayed inside the dropzone.
 * @param {[]} uploadedFiles - Array with all the chosen files.
 * @param {CallableFunction} setUploadedFiles - React state setter to update the chosen uploadedFiles array.
 */
const AdaptedDropzone = ({ reset, label, uploadedFiles, setUploadedFiles }) => {
  const theme = useTheme();

  const { t } = useTranslation();

  useEffect(() => {
    if (reset) {
      setUploadedFiles([]);
    }
  }, [reset, setUploadedFiles]);

  const handleFiles = (newFiles) => {
    setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleDelete = (index) => {
    setUploadedFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);
      return newFiles;
    });
  };

  const onDrop = (acceptedFiles) => {
    handleFiles(acceptedFiles);
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      accept: {
        "image/jpeg": [],
        "image/png": [],
        "application/pdf": [],
        "application/json": [],
        "application/xml": [".xml"],
        "application/hl7": [],
        "text/csv": [],
      },
    });

  const style = useMemo(() => {
    const baseStyle = {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: theme.spacing(2),
      borderWidth: 2,
      borderRadius: theme.shape.borderRadius,
      borderColor: `${theme.palette.neutral.dark}`,
      borderStyle: "dashed",
      backgroundColor: `${theme.palette.neutral.main} !important`,
      color: "#bdbdbd",
      outline: "none",
      transition: "border .24s ease-in-out",
      height: 300,
    };

    const focusedStyle = {
      borderColor: theme.palette.primary.main,
    };

    const acceptStyle = {
      borderColor: theme.palette.success.main,
    };

    const rejectStyle = {
      borderColor: theme.palette.error.main,
    };

    return {
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    };
  }, [isFocused, isDragAccept, isDragReject, theme]);

  return (
    <div className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {uploadedFiles.length === 0 && (
          <UploadFileOutlinedIcon fontSize="large" />
        )}
        {uploadedFiles.length === 0 && <p>{label}</p>}
        {uploadedFiles.length > 0 && (
          <UploadFileOutlinedIcon fontSize="large" color="red" />
        )}
        {uploadedFiles.length > 0 && (
          <p>
            {t("scenes.analysis.inputs.dropzoneUploaded")}{" "}
            {uploadedFiles[uploadedFiles.length - 1].path}
          </p>
        )}
      </div>
      <div>
        <Stack direction="row" spacing={1} margin={theme.spacing(1)}>
          {uploadedFiles.map((file, index) => (
            <Chip
              key={index}
              label={file.name}
              onDelete={() => handleDelete(index)}
            />
          ))}
        </Stack>
      </div>
    </div>
  );
};

export default AdaptedDropzone;
