import { createTheme } from "@mui/material/styles";
import { createContext, useEffect, useMemo, useState } from "react";

import SourceSansProBlack from "./fonts/sourcesanspro-black-webfont.woff2";
import SourceSansProBlackItalic from "./fonts/sourcesanspro-blackitalic-webfont.woff2";
import SourceSansProBold from "./fonts/sourcesanspro-bold-webfont.woff2";
import SourceSansProBoldItalic from "./fonts/sourcesanspro-bolditalic-webfont.woff2";
import SourceSansProExtralight from "./fonts/sourcesanspro-extralight-webfont.woff2";
import SourceSansProExtralightItalic from "./fonts/sourcesanspro-extralightitalic-webfont.woff2";
import SourceSansProItalic from "./fonts/sourcesanspro-italic-webfont.woff2";
import SourceSansProLight from "./fonts/sourcesanspro-light-webfont.woff2";
import SourceSansProLightItalic from "./fonts/sourcesanspro-lightitalic-webfont.woff2";
import SourceSansProRegular from "./fonts/sourcesanspro-regular-webfont.woff2";
import SourceSansProSemibold from "./fonts/sourcesanspro-semibold-webfont.woff2";
import SourceSansProSemiboldItalic from "./fonts/sourcesanspro-semibolditalic-webfont.woff2";
import { useSelector } from "react-redux";

export const tokens = (mode) => ({
  ...(mode === "dark"
    ? {
        grey: {
          100: "#e0e0e0",
          200: "#c2c2c2",
          300: "#a3a3a3",
          400: "#858585",
          500: "#666666",
          600: "#525252",
          700: "#3d3d3d",
          800: "#292929",
          900: "#141414",
        },
        primary: {
          100: "#d0d1d5",
          200: "#a1a4ab",
          300: "#727681",
          400: "#1F2A40",
          500: "#141b2d",
          600: "#101624",
          700: "#0c101b",
          800: "#080b12",
          900: "#040509",
        },
        greenAccent: {
          100: "#d7f2de",
          200: "#afe5be",
          300: "#87d99d",
          400: "#5fcc7d",
          500: "#37bf5c",
          600: "#2c994a",
          700: "#217337",
          800: "#164c25",
          900: "#0b2612",
        },
        redAccent: {
          100: "#f7dedd",
          200: "#efbcbb",
          300: "#e69b98",
          400: "#de7976",
          500: "#d65854",
          600: "#ab4643",
          700: "#803532",
          800: "#562322",
          900: "#2b1211",
        },
        blueAccent: {
          100: "#e1e2fe",
          200: "#c3c6fd",
          300: "#a4a9fc",
          400: "#868dfb",
          500: "#6870fa",
          600: "#535ac8",
          700: "#3e4396",
          800: "#2a2d64",
          900: "#151632",
        },
        yellowAccent: {
          100: "#fcfee1",
          200: "#f9fdc3",
          300: "#f6fca4",
          400: "#f3fb86",
          500: "#f0fa68",
          600: "#c0c853",
          700: "#90963e",
          800: "#60642a",
          900: "#303215",
        },
        red: {
          100: "#f8d1d0",
          200: "#f1a3a1",
          300: "#e97672",
          400: "#e24843",
          500: "#db1a14",
          600: "#af1510",
          700: "#83100c",
          800: "#580a08",
          900: "#2c0504",
        },
      }
    : {
        grey: {
          100: "#141414",
          200: "#292929",
          300: "#3d3d3d",
          400: "#525252",
          500: "#666666",
          600: "#858585",
          700: "#a3a3a3",
          800: "#c2c2c2",
          900: "#e0e0e0",
        },
        primary: {
          100: "#040509",
          200: "#080b12",
          300: "#0c101b",
          400: "#f2f0f0",
          500: "#141b2d",
          600: "#1F2A40",
          700: "#727681",
          800: "#a1a4ab",
          900: "#d0d1d5",
        },
        greenAccent: {
          100: "#0b2612",
          200: "#164c25",
          300: "#217337",
          400: "#2c994a",
          500: "#37bf5c",
          600: "#5fcc7d",
          700: "#87d99d",
          800: "#afe5be",
          900: "#d7f2de",
        },
        redAccent: {
          100: "#2b1211",
          200: "#562322",
          300: "#803532",
          400: "#ab4643",
          500: "#d65854",
          600: "#de7976",
          700: "#e69b98",
          800: "#efbcbb",
          900: "#f7dedd",
        },
        blueAccent: {
          100: "#151632",
          200: "#2a2d64",
          300: "#3e4396",
          400: "#535ac8",
          500: "#6870fa",
          600: "#868dfb",
          700: "#a4a9fc",
          800: "#c3c6fd",
          900: "#e1e2fe",
        },
        yellowAccent: {
          100: "#303215",
          200: "#60642a",
          300: "#90963e",
          400: "#c0c853",
          500: "#f0fa68",
          600: "#f3fb86",
          700: "#f6fca4",
          800: "#f9fdc3",
          900: "#fcfee1",
        },
        red: {
          100: "#2c0504",
          200: "#580a08",
          300: "#83100c",
          400: "#af1510",
          500: "#db1a14",
          600: "#e24843",
          700: "#e97672",
          800: "#f1a3a1",
          900: "#f8d1d0",
        },
      }),
});

export const themeSettings = (mode) => {
  const colors = tokens(mode);
  return {
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          @font-face {
            font-family: "Source Sans Pro";
            font-style: normal;
            font-display: swap;
            font-weight: 400;
            src: local("Source Sans Pro Regular"), local("Source-Sans-Pro-Regular"), url(${SourceSansProRegular}) format("woff2");
          }
          @font-face {
            font-family: "Source Sans Pro";
            font-style: normal;
            font-display: swap;
            font-weight: 900;
            src: local("Source Sans Pro Black"), local("Source-Sans-Pro-Black"), url(${SourceSansProBlack}) format("woff2");
          }
          @font-face {
            font-family: "Source Sans Pro";
            font-style: italic;
            font-display: swap;
            font-weight: 900;
            src: local("Source Sans Pro BlackItalic"), local("Source-Sans-Pro-BlackItalic"), url(${SourceSansProBlackItalic}) format("woff2");
          }
          @font-face {
            font-family: "Source Sans Pro";
            font-style: normal;
            font-display: swap;
            font-weight: 700;
            src: local("Source Sans Pro Bold"), local("Source-Sans-Pro-Bold"), url(${SourceSansProBold}) format("woff2");
          }
          @font-face {
            font-family: "Source Sans Pro";
            font-style: italic;
            font-display: swap;
            font-weight: 700;
            src: local("Source Sans Pro BoldItalic"), local("Source-Sans-Pro-BoldItalic"), url(${SourceSansProBoldItalic}) format("woff2");
          }
          @font-face {
            font-family: "Source Sans Pro";
            font-style: normal;
            font-display: swap;
            font-weight: 200;
            src: local("Source Sans Pro Extralight"), local("Source-Sans-Pro-Extralight"), url(${SourceSansProExtralight}) format("woff2");
          }
          @font-face {
            font-family: "Source Sans Pro";
            font-style: italic;
            font-display: swap;
            font-weight: 200;
            src: local("Source Sans Pro ExtralightItalic"), local("Source-Sans-Pro-ExtralightItalic"), url(${SourceSansProExtralightItalic}) format("woff2");
          }
          @font-face {
            font-family: "Source Sans Pro";
            font-style: italic;
            font-display: swap;
            font-weight: 400;
            src: local("Source Sans Pro Italic"), local("Source-Sans-Pro-Italic"), url(${SourceSansProItalic}) format("woff2");
          }
          @font-face {
            font-family: "Source Sans Pro";
            font-style: normal;
            font-display: swap;
            font-weight: 300;
            src: local("Source Sans Pro Light"), local("Source-Sans-Pro-Light"), url(${SourceSansProLight}) format("woff2");
          }
          @font-face {
            font-family: "Source Sans Pro";
            font-style: italic;
            font-display: swap;
            font-weight: 300;
            src: local("Source Sans Pro LightItalic"), local("Source-Sans-Pro-LightItalic"), url(${SourceSansProLightItalic}) format("woff2");
          }
          @font-face {
            font-family: "Source Sans Pro";
            font-style: normal;
            font-display: swap;
            font-weight: 600;
            src: local("Source Sans Pro Semibold"), local("Source-Sans-Pro-Semibold"), url(${SourceSansProSemibold}) format("woff2");
          }
          @font-face {
            font-family: "Source Sans Pro";
            font-style: italic;
            font-display: swap;
            font-weight: 600;
            src: local("Source Sans Pro SemiboldItalic"), local("Source-Sans-Pro-SemiboldItalic"), url(${SourceSansProSemiboldItalic}) format("woff2");
          }
        `,
      },
    },
    palette: {
      mode,
      ...(mode === "dark"
        ? {
            primary: {
              main: colors.redAccent[500],
            },
            secondary: {
              main: colors.blueAccent[500],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            success: {
              main: colors.greenAccent[500],
            },
            error: {
              main: colors.red[500],
            },
            background: {
              default: colors.grey[900],
              darker: "#242629",
              dark: colors.primary[900],
            },
            text: {
              main: colors.grey[100],
              light: colors.grey[300],
            },
          }
        : {
            primary: {
              main: colors.redAccent[500],
            },
            secondary: {
              main: colors.blueAccent[500],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            success: {
              main: colors.greenAccent[500],
            },
            background: {
              default: "#fcfcfc",
              darker: colors.primary[400],
              dark: colors.primary[900],
            },
            text: {
              main: colors.grey[100],
              light: colors.grey[300],
            },
          }),
    },
    typography: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
    shape: {
      borderRadius: 2,
    },
  };
};

export const ColorModeContext = createContext({
  setMode: (mode) => {},
  toggleMode: () => {},
});

export const useMode = () => {
  const user = useSelector((state) => state.user);
  const [mode, setMode] = useState(user.themePreference);

  useEffect(() => {
    const storedTheme = user.themePreference;
    if (storedTheme) {
      setMode(storedTheme === "dark" ? "dark" : "light");
    } else {
      setMode("light");
    }
  }, [user.themePreference]);

  const toggleMode = () => {
    setMode((prev) => {
      const newMode = prev === "light" ? "dark" : "light";
      return newMode;
    });
  };

  const colorMode = useMemo(
    () => ({
      setMode,
      toggleMode,
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return [theme, colorMode];
};
