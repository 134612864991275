import { Box, useTheme } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import logoBlack from "../../resources/LogoTextBlack.png";
import logoWhite from "../../resources/LogoTextWhite.png";
import team from "../../resources/team.jpg";
import { styles } from "../sharedStyles";

const About = () => {
  const theme = useTheme();

  const { t } = useTranslation();

  return (
    <Box sx={styles(theme).mainContent}>
      <div>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginBottom={theme.spacing(2)}
        >
          <img
            alt="KardioIQ"
            src={theme.palette.mode === "dark" ? logoWhite : logoBlack}
            width="400"
          />
        </Box>
        <div style={{ maxWidth: "90ch", margin: "0 auto" }}>
          <p>{t("scenes.about.paragraph1")}</p>
          <p>{t("scenes.about.paragraph2")}</p>
        </div>
        <Box display="flex" justifyContent="center" alignItems="center">
          <img alt="KardioIQ Team" src={team} width="650" border={1} />
        </Box>
      </div>
    </Box>
  );
};

export default About;
