import { useTranslation } from "react-i18next";

export const GeneralQuestions = () => {
  const { t } = useTranslation();

  return [
    {
      id: 1,
      p_name: "panel1-general",
      question: t("scenes.faq.generalQuestions.panel1.question"),
      texts: t("scenes.faq.generalQuestions.panel1.texts", {
        returnObjects: true,
      }),
    },
    {
      id: 2,
      p_name: "panel2-general",
      question: t("scenes.faq.generalQuestions.panel2.question"),
      texts: t("scenes.faq.generalQuestions.panel2.texts", {
        returnObjects: true,
      }),
    },
    {
      id: 3,
      p_name: "panel3-general",
      question: t("scenes.faq.generalQuestions.panel3.question"),
      texts: t("scenes.faq.generalQuestions.panel3.texts", {
        returnObjects: true,
      }),
    },
  ];
};

export const TechQuestions = () => {
  const { t } = useTranslation();

  return [
    {
      id: 1,
      p_name: "panel1-tech",
      question: t("scenes.faq.techQuestions.panel1.question"),
      texts: t("scenes.faq.techQuestions.panel1.texts", {
        returnObjects: true,
      }),
    },
    {
      id: 2,
      p_name: "panel2-tech",
      question: t("scenes.faq.techQuestions.panel2.question"),
      texts: t("scenes.faq.techQuestions.panel2.texts", {
        returnObjects: true,
      }),
    },
    {
      id: 3,
      p_name: "panel3-tech",
      question: t("scenes.faq.techQuestions.panel3.question"),
      texts: t("scenes.faq.techQuestions.panel3.texts", {
        returnObjects: true,
      }),
    },
    {
      id: 4,
      p_name: "panel4-tech",
      question: t("scenes.faq.techQuestions.panel4.question"),
      texts: t("scenes.faq.techQuestions.panel4.texts", {
        returnObjects: true,
      }),
    },
    {
      id: 5,
      p_name: "panel5-tech",
      question: t("scenes.faq.techQuestions.panel5.question"),
      texts: t("scenes.faq.techQuestions.panel5.texts", {
        returnObjects: true,
      }),
    },
    {
      id: 6,
      p_name: "panel6-tech",
      question: t("scenes.faq.techQuestions.panel6.question"),
      texts: t("scenes.faq.techQuestions.panel6.texts", {
        returnObjects: true,
      }),
    },
    {
      id: 7,
      p_name: "panel7-tech",
      question: t("scenes.faq.techQuestions.panel7.question"),
      texts: t("scenes.faq.techQuestions.panel7.texts", {
        returnObjects: true,
      }),
    },
  ];
};

export const ErrorQuestions = () => {
  const { t } = useTranslation();

  return [
    {
      id: 1,
      p_name: "panel1-error",
      question: t("scenes.faq.errorQuestions.panel1.question"),
      texts: t("scenes.faq.errorQuestions.panel1.texts", {
        returnObjects: true,
      }),
    },
    {
      id: 2,
      p_name: "panel2-error",
      question: t("scenes.faq.errorQuestions.panel2.question"),
      texts: t("scenes.faq.errorQuestions.panel2.texts", {
        returnObjects: true,
      }),
    },
  ];
};
