import { Box, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ECGController from "../../components/ECGController";
import KpiGridPrint from "../../components/KpiGridPrint";
import LogoHeartBlack from "../../resources/LogoHeartBlack.png";
import kardioiqlogoblack from "../../resources/kardioiqlogoblack.png";
// import kardioiqlogoblack from "../../resources/hermiqslogoblack.png";
import { getCurrentDate } from "../../utils/currentDate";

const Header = ({ patientId }) => {
  const user = useSelector((state) => state.user);
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "0.5cm",
        }}
      >
        <Typography
          style={{ maxWidth: "25%", color: theme.palette.text.secondary }}
          variant="h5"
        >
          {t("printView.headerText")}{" "}
          <span
            style={{ color: theme.palette.text.primary, fontWeight: "bold" }}
          >
            {patientId}
          </span>
        </Typography>
        <div>
          <img alt="KardioIQ Logo" src={kardioiqlogoblack} width="100px" />
        </div>
        <Typography
          style={{
            maxWidth: "25%",
            textAlign: "right",
            color: theme.palette.text.secondary,
          }}
          variant="h5"
        >
          {getCurrentDate(user.languagePreference)}
        </Typography>
      </div>
      <hr
        style={{ marginBottom: "0.5cm", color: theme.palette.neutral.dark }}
      />
    </>
  );
};

const PrintView = ({
  kpi,
  pathologies,
  pathologyProbabilities,
  data,
  patientId,
  sex,
}) => {
  const { t } = useTranslation();

  let pageNumber = 2;
  const maxPageNumber = pathologies.length * 2 + 1;

  return (
    <div id="printView">
      <Header patientId={patientId} />
      <div>
        <Typography
          textAlign={"center"}
          variant="h3"
          fontWeight={"bold"}
          marginBottom={2}
        >
          {t("printView.kpi")}
        </Typography>
        <KpiGridPrint kpiObjects={kpi} sex={sex} />
      </div>

      <img
        alt="KardioIQ Logo"
        src={LogoHeartBlack}
        width="100%"
        style={{
          opacity: 0.15,
          position: "absolute",
          left: 0,
          top: 0,
          transform: "translateY(125%)",
        }}
      />

      <p style={{ position: "absolute", bottom: 0, right: 0 }}>
        1 / {maxPageNumber}
      </p>
      {pathologies.map((pathology, index) => {
        const firstPageNumber = pageNumber++;
        const secondPageNumber = pageNumber++;

        return (
          <Box key={index}>
            <div className="page" style={{ position: "relative" }}>
              <Header patientId={patientId} />
              <Typography
                textAlign={"center"}
                variant="h3"
                fontWeight={"bold"}
                marginBottom={2}
              >
                {t("printView.pathologies")}
              </Typography>
              <Typography
                textAlign={"center"}
                variant="h4"
                marginBottom={2}
                className="pageHeader"
              >
                {t(`pathologies.${pathology.split("_explanation")[0]}`)} (
                {pathologyProbabilities[index].value.toFixed(1)}%)
              </Typography>
              <ECGController
                idAppendix={pathology + "1"}
                currentExplanation={pathology}
                data={data}
                filterLeads={["I", "II", "III", "aVF", "aVL", "aVR"]}
                ecgHeight={200}
                ecgWidth={"20cm"}
              />
              <p style={{ position: "absolute", bottom: -300, right: 0 }}>
                {firstPageNumber} / {maxPageNumber}
              </p>
            </div>
            <div className="page" style={{ position: "relative" }}>
              <Header patientId={patientId} />
              <Typography
                textAlign={"center"}
                variant="h3"
                fontWeight={"bold"}
                marginBottom={2}
              >
                {t("printView.pathologies")}
              </Typography>
              <Typography
                textAlign={"center"}
                variant="h4"
                marginBottom={2}
                className="pageHeader"
              >
                {t(`pathologies.${pathology.split("_explanation")[0]}`)} (
                {pathologyProbabilities[index].value.toFixed(1)}%)
              </Typography>
              <ECGController
                idAppendix={pathology + "2"}
                currentExplanation={pathology}
                data={data}
                filterLeads={["V1", "V2", "V3", "V4", "V5", "V6"]}
                ecgHeight={200}
                ecgWidth={"20cm"}
              />
              <p style={{ position: "absolute", bottom: -300, right: 0 }}>
                {secondPageNumber} / {maxPageNumber}
              </p>
            </div>
          </Box>
        );
      })}
    </div>
  );
};

export default PrintView;
