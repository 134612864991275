import { Box, FormControl, Typography } from "@mui/material";

/**
 * Formatted label for input elements in the settings page.
 * 
 * @component
 * @example
 * 
 * return (
 *  <LabelledInput label={"Label"} sx={{ margin: "auto" }}>
 *    <TextField />
 *  </LabelledInput>
 * )
 * 
 * @param {React.FC} children - The react component that should be displayed inside the component.
 * @param {string} label - The rendered label in front of the input field. 
 * @param {{}} sx - Material UI style object to customize the styles.
 */
export const LabelledInput = ({ children, label, sx }) => {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}
      width={"60%"}
      marginY={1}
    >
      <Typography variant="h5" fontWeight={"bold"}>
        {label}
      </Typography>
      <Box width={"70%"}>
        <FormControl sx={sx} fullWidth>
          {children}
        </FormControl>
      </Box>
    </Box>
  );
};
