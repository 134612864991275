import React, { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchUserSettings } from "../state/userSlice";
import { ColorModeContext } from "../theme";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();

  const { i18n } = useTranslation();

  const { setMode } = useContext(ColorModeContext);

  const [authToken, setAuthToken] = useState(
    localStorage.getItem("access") || null
  );
  const [isAuthenticated, setIsAuthenticated] = useState(!!authToken);

  const navigate = useNavigate();

  useEffect(() => {
    if (authToken) localStorage.setItem("token", authToken);
    else localStorage.removeItem("token");
  }, [authToken]);

  const login = async (token) => {
    try {
      setAuthToken(token);
      setIsAuthenticated(true);

      await dispatch(fetchUserSettings())
        .unwrap()
        .then((userSettings) => {
          const { themePreference, languagePreference, email } = userSettings;

          localStorage.setItem("user", email);

          setMode(themePreference);
          i18n.changeLanguage(languagePreference);
        })
        .catch((error) => {
          console.error("Error fetching user settings:", error);
        });
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  const logout = () => {
    setAuthToken(null);
    setIsAuthenticated(false);
    setMode("light");
    navigate("/analyse");
    localStorage.clear();
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, authToken, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
