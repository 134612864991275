import {
  AnnouncementOutlined,
  AvTimer,
  Brush,
  BrushOutlined,
  Dangerous,
  DoDisturb,
  GridOn,
  LinearScale,
  MonitorHeart,
  ReportProblem,
  Straighten,
  TrendingDown,
  TrendingUp,
  ZoomIn,
  ZoomOut,
} from "@mui/icons-material";
import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { PresetColors } from "../data/colorTheme";
import { ECGViewer, getZoom, handleZoom, redraw, resetView } from "./ECGViewer";
import LeadsConfiguration from "./LeadsConfiguration";
import CustomTooltip from "./Tooltip";

/**
 * Implements the ecgViewer with controls for it.
 * 
 * @component
 * @example
 * const ecgViewerData = {...};
 * const [displayKpi, setDisplayKpi] = useState(true);
 * 
 * return (
 *  <ECGController
      data={ecgViewerData}
      currentExplanation={"I"}
      ecgHeight={"90vh"}
      ecgWidth={"100%"}
      kpiDisplay={displayKpi}
      setKpiDisplay={setDisplayKpi}
      unhealthyCount={3}
      criticalCount={2}
    />
 * )
 * 
 * @param {{}} data - The prepared ecg data fetched from the backend.
 * @param {string} currentExplanation - The lead, that should get the explanation highlights. Should be switchable.
 * @param {string} ecgHeight - The height of the viewer container.
 * @param {string} ecgWidth - The width of the viewer container.
 * @param {boolean} kpiDisplay - Wether or not the kpis get displayed on the right side. Should be switchable. 
 * @param {CallableFunction} setKpiDisplay - React state setter to switch the kpiDisplay state.
 * @param {number} unhealthyCount - The length of the array containing all unhealthy conditions.
 * @param {number} criticalCount - The length of the array containing all critical conditions.
 * @returns 
 */
export default function ECGController(props) {
  const user = useSelector((state) => state.user);

  const { t } = useTranslation();
  let allLeads;

  if (props.filterLeads) {
    allLeads = props.filterLeads;
  } else {
    allLeads = Object.keys(props.data.ecg);
  }

  const [leads, setLeads] = useState(user.leadsPreference);
  const [printLeads] = useState(allLeads);
  const [speed, setSpeed] = useState(user.speedPreference);
  const [measure, setMeasure] = useState("");
  const [marking, setMarking] = useState(false);
  const [snap, setSnap] = useState("");
  const [displayed, setDisplayed] = useState(["grid", "explainability", "markings"]);
  const [currentZoom, setCurrentZoom] = useState("100%");

  const theme = useTheme();
  const buttonHeight = 50;

  const ecgViewerColors = {};

  for (const [key, value] of Object.entries(user.ecgViewerColors)) {
    ecgViewerColors[key] = PresetColors()[value].code;
  }

  const ecgFont = theme.typography.h2;

  useEffect(() => {
    setLeads(user.leadsPreference);
  }, [user.leadsPreference]);

  return (
    <>
      <Box
        gridArea={"controls"}
        className="ecg-viewer-toolbar"
        display={"flex"}
        alignItems={"center"}
        gap={theme.spacing(1)}
        margin={`${theme.spacing(3)} 0 ${theme.spacing(1)}`}
      >
        <Box>
          <Typography variant="h6" sx={{ color: theme.palette.text.disabled }}>
            {t("ecgViewer.toolboxHeading.zoom")}
          </Typography>
          <ButtonGroup
            color="text"
            variant="outlined"
            orientation="horizontal"
            sx={{ height: buttonHeight }}
          >
            <Button
              aria-label="zoomin"
              onClick={() => {
                handleZoom({ deltaY: -1, offsetX: 1, offsetY: 1 });
                redraw();
              }}
            >
              <ZoomIn />
            </Button>
            <Button
              aria-label="zoomreset"
              onClick={() => {
                resetView({ zoomFactor: 1, x: 0, y: 0 });
                redraw();
              }}
            >
              {currentZoom}
            </Button>
            <Button
              aria-label="zoomout"
              onClick={() => {
                handleZoom({ deltaY: 1, offsetX: 1, offsetY: 1 });
                redraw();
              }}
            >
              <ZoomOut />
            </Button>
          </ButtonGroup>
        </Box>

        <Box>
          <Typography variant="h6" sx={{ color: theme.palette.text.disabled }}>
            {t("ecgViewer.toolboxHeading.display")}
          </Typography>
          <ToggleButtonGroup
            color="text"
            variant="outlined"
            orientation="horizontal"
            value={displayed}
            onChange={(event, value) => {
              setDisplayed(value);
            }}
            sx={{ height: buttonHeight }}
          >
            <ToggleButton value="grid">
              <CustomTooltip title={t("ecgViewer.tooltips.toggleGrid")}>
                <GridOn />
              </CustomTooltip>
            </ToggleButton>
            <ToggleButton value="explainability">
              <CustomTooltip
                title={t("ecgViewer.tooltips.toggleExplainability")}
              >
                <MonitorHeart />
              </CustomTooltip>
            </ToggleButton>
            <ToggleButton value="timeline">
              <CustomTooltip title={t("ecgViewer.tooltips.toggleTimeline")}>
                <AvTimer />
              </CustomTooltip>
            </ToggleButton>
            <ToggleButton value="markings">
              <CustomTooltip title={t("ecgViewer.tooltips.toggleMarkings")}>
                <BrushOutlined />
              </CustomTooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        <Box>
          <Typography variant="h6" sx={{ color: theme.palette.text.disabled }}>
            {t("ecgViewer.toolboxHeading.measurement")}
          </Typography>
          <ToggleButtonGroup
            color="text"
            exclusive
            orientation="horizontal"
            value={measure}
            onChange={(_, value) => {
              if (value !== null) {
                setMarking(false);
                setMeasure(value);
              }
            }}
            sx={{ height: buttonHeight }}
          >
            <ToggleButton value="">
              <DoDisturb />
            </ToggleButton>
            <ToggleButton value="ruler">
              <CustomTooltip title={t("ecgViewer.tooltips.toggleRuler")}>
                <Straighten />
              </CustomTooltip>
            </ToggleButton>
            <ToggleButton value="digital">
              <CustomTooltip title={t("ecgViewer.tooltips.toggleDigital")}>
                <LinearScale />
              </CustomTooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        {measure && (
          <Box>
            <Typography
              variant="h6"
              sx={{ color: theme.palette.text.disabled }}
            >
              {t("ecgViewer.toolboxHeading.snap")}
            </Typography>
            <ToggleButtonGroup
              exclusive
              orientation="horizontal"
              value={snap}
              onChange={(_, value) => {
                setSnap(value);
              }}
              sx={{ height: buttonHeight }}
            >
              <ToggleButton value="top">
                <CustomTooltip title={t("ecgViewer.tooltips.toggleSnapTop")}>
                  <TrendingUp />
                </CustomTooltip>
              </ToggleButton>
              <ToggleButton value="bottom">
                <CustomTooltip title={t("ecgViewer.tooltips.toggleSnapBottom")}>
                  <TrendingDown />
                </CustomTooltip>
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        )}

        <Box>
          <Typography variant="h6" sx={{ color: theme.palette.text.disabled }}>
            {t("ecgViewer.toolboxHeading.mark")}
          </Typography>
          <ToggleButton
            color="text"
            value="check"
            selected={marking}
            onChange={() => {
              setMeasure("");
              setMarking(!marking);
            }}
            sx={{ height: buttonHeight }}
          >
            <CustomTooltip title={t("ecgViewer.tooltips.toggleMark")}>
              <Brush />
            </CustomTooltip>
          </ToggleButton>
        </Box>

        <Box>
          <Typography variant="h6" sx={{ color: theme.palette.text.disabled }}>
            {t("ecgViewer.toolboxHeading.leads")}
          </Typography>
          <LeadsConfiguration
            sx={{ height: buttonHeight, minWidth: 180 }}
            setTemporaryLeads={setLeads}
          />
        </Box>

        <Box>
          <Typography variant="h6" sx={{ color: theme.palette.text.disabled }}>
            {t("ecgViewer.toolboxHeading.speed")}
          </Typography>
          <Select
            color="text"
            value={speed}
            onChange={(event) => setSpeed(event.target.value)}
            sx={{ height: buttonHeight }}
          >
            <MenuItem value={25}>{t("ecgViewer.speed.twentyfive")}</MenuItem>
            <MenuItem value={50}>{t("ecgViewer.speed.fifty")}</MenuItem>
            <MenuItem value={100}>{t("ecgViewer.speed.onehundred")}</MenuItem>
          </Select>
        </Box>

        {user.kpiOrder.length > 0 && (
          <Box marginLeft={"auto"}>
            <Typography
              variant="h6"
              sx={{ color: theme.palette.text.disabled }}
            >
              {t("ecgViewer.toolboxHeading.kpi")}
            </Typography>
            <Button
              variant="outlined"
              color="text"
              onClick={() => {
                props.setKpiDisplay(!props.kpiDisplay);
              }}
              sx={{ height: buttonHeight }}
            >
              <CustomTooltip title={t("ecgViewer.tooltips.kpi")}>
                <Box display={"flex"} gap={theme.spacing(2)}>
                  {(props.criticalCount > 0 || props.unhealthyCount > 0) && (
                    <Badge
                      badgeContent={
                        props.criticalCount > 0 ? (
                          <Dangerous
                            color="error"
                            sx={{
                              backgroundColor: theme.palette.background.darker,
                              borderRadius: "50%",
                            }}
                          />
                        ) : (
                          <ReportProblem color="warning" />
                        )
                      }
                    >
                      <AnnouncementOutlined
                        sx={{ fontSize: theme.typography.h3.fontSize }}
                      />
                    </Badge>
                  )}
                  {props.unhealthyCount <= 0 && props.criticalCount <= 0 && (
                    <AnnouncementOutlined
                      sx={{ fontSize: theme.typography.h3.fontSize }}
                    />
                  )}
                </Box>
              </CustomTooltip>
            </Button>
          </Box>
        )}
      </Box>
      <Box
        gridArea={"viewer"}
        className="ecg-viewer-container"
        border={`2px solid ${theme.palette.text.main}`}
        borderRadius={theme.shape.borderRadius}
        sx={{ height: "90vh", overflow: "hidden" }}
      >
        <ECGViewer
          singleColumn={user.singleColumn}
          data={props.data}
          idAppendix={props.idAppendix ? props.idAppendix : ""}
          currentExplanation={props.currentExplanation}
          ecgWidth={props.ecgWidth}
          ecgHeight={props.ecgHeight}
          leads={props.filterLeads ? printLeads : leads}
          speed={speed}
          measure={measure}
          mark={marking}
          displayed={displayed}
          colors={ecgViewerColors}
          font={ecgFont}
          snap={snap}
          zoomCallback={() => {
            setCurrentZoom(getZoom());
          }}
          translations={t}
        />
      </Box>
    </>
  );
}
