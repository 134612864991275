import { Tooltip, Zoom, styled, tooltipClasses } from "@mui/material";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    TransitionComponent={Zoom}
    placement="top"
    arrow
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: theme.typography.fontSize,
    backgroundColor: theme.palette.neutral.main,
    color: theme.palette.common.white,
  },
}));

export default CustomTooltip;
