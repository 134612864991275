import {
  Box,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { changeLeadsPreference, updateUserSettings } from "../state/userSlice";
import { useEffect } from "react";

/**
 * Component that lets users choose which leads should be displayed in the ecgViewer.
 * 
 * @component
 * @example
 * const [leads, setLeads] = useState([]);
 * 
 * return(
 *  <LeadsConfiguration 
 *    saveSettings={false}
 *    sx={{ margin: "auto" }}
 *    setTemporaryLeads={setLeads}
 *  />
 * )
 * 
 * @param {boolean} saveSettings - Whether or not the settings changed in this component should be saved for the user.
 * @param {{}} sx - Material UI style object to apply custom styles.
 * @param {CallableFunction} setTemporaryLeads - If settings should not be saved for the user, provide a setter for a leads state. 
 * @returns 
 */
const LeadsConfiguration = ({
  saveSettings = false,
  sx,
  setTemporaryLeads = undefined,
}) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();

  const allLeads = [
    "I",
    "II",
    "III",
    "aVR",
    "aVL",
    "aVF",
    "V1",
    "V2",
    "V3",
    "V4",
    "V5",
    "V6",
  ];

  const limbLeads = ["I", "II", "III", "aVR", "aVL", "aVF"];
  const precordialLeads = ["V1", "V2", "V3", "V4", "V5", "V6"];

  const [leads, setLeads] = useState(user.leadsPreference);

  const handleLeadConfigurationChange = (event) => {
    const newValue = event.target.value;
    if (newValue.indexOf("all") !== -1) {
      if (leads.length === allLeads.length) {
        setLeads([]);
        if (!saveSettings) return;
        dispatch(changeLeadsPreference([]));
        dispatch(updateUserSettings({ leadsPreference: [] }));
      } else {
        setLeads(allLeads);
        if (!saveSettings) return;
        dispatch(changeLeadsPreference(allLeads));
        dispatch(updateUserSettings({ leadsPreference: allLeads }));
      }
    } else if (newValue.indexOf("allLimb") !== -1) {
      if (limbLeads.every((lead) => leads.includes(lead))) {
        const newLeadsArray = leads.filter((lead) => !limbLeads.includes(lead));
        setLeads(newLeadsArray);
        if (!saveSettings) return;
        dispatch(changeLeadsPreference(newLeadsArray));
        dispatch(updateUserSettings({ leadsPreference: newLeadsArray }));
      } else {
        const newLeadsArray = leads.concat(limbLeads);
        setLeads(newLeadsArray);
        if (!saveSettings) return;
        dispatch(changeLeadsPreference(newLeadsArray));
        dispatch(updateUserSettings({ leadsPreference: newLeadsArray }));
      }
    } else if (newValue.indexOf("allPrecordial") !== -1) {
      if (precordialLeads.every((lead) => leads.includes(lead))) {
        const newLeadsArray = leads.filter(
          (lead) => !precordialLeads.includes(lead)
        );
        setLeads(newLeadsArray);
        if (!saveSettings) return;
        dispatch(changeLeadsPreference(newLeadsArray));
        dispatch(updateUserSettings({ leadsPreference: newLeadsArray }));
      } else {
        const newLeadsArray = leads.concat(precordialLeads);
        setLeads(newLeadsArray);
        if (!saveSettings) return;
        dispatch(changeLeadsPreference(newLeadsArray));
        dispatch(updateUserSettings({ leadsPreference: newLeadsArray }));
      }
    } else {
      const result = [];
      for (let lead of allLeads) {
        if (newValue.indexOf(lead) !== -1) result.push(lead);
      }
      setLeads(result);
      if (!saveSettings) return;
      dispatch(changeLeadsPreference(result));
      dispatch(updateUserSettings({ leadsPreference: result }));
    }
  };

  useEffect(() => {
    if (setTemporaryLeads !== undefined) {
      setTemporaryLeads(leads);
    }
  }, [leads, setTemporaryLeads]);

  return (
    <Box position={"relative"}>
      <Select
        id="lead-select"
        value={leads}
        onChange={handleLeadConfigurationChange}
        renderValue={(selected) =>
          `${t("ecgViewer.leads.selected")} (${
            selected.length === allLeads.length
              ? t("ecgViewer.leads.all")
              : selected.length
          })`
        }
        multiple
        fullWidth
        sx={sx}
      >
        <MenuItem value="all" sx={{ paddingLeft: 0}}>
          <Checkbox checked={leads.length === allLeads.length} />
          <ListItemText>{t("ecgViewer.leads.all")}</ListItemText>
        </MenuItem>
        <MenuItem
          value="allLimb"
          sx={{ backgroundColor: theme.palette.background.dark, paddingLeft: theme.spacing(2) }}
        >
          <Checkbox checked={limbLeads.every((lead) => leads.includes(lead))} />
          <Typography variant="h6" fontWeight={"bold"}>
            {t("misc.leadGroups.limb")}
          </Typography>
        </MenuItem>
        {limbLeads.map((lead, index) => (
          <MenuItem
            key={index}
            value={lead}
            sx={{ paddingLeft: theme.spacing(4) }}
          >
            <Checkbox checked={leads.indexOf(lead) > -1} />
            <ListItemText primary={lead} />
          </MenuItem>
        ))}
        <MenuItem
          value="allPrecordial"
          sx={{ backgroundColor: theme.palette.background.dark }}
        >
          <Checkbox
            checked={precordialLeads.every((lead) => leads.includes(lead))}
          />
          <Typography variant="h6" fontWeight={"bold"}>
            {t("misc.leadGroups.precordial")}
          </Typography>
        </MenuItem>
        {precordialLeads.map((lead, index) => (
          <MenuItem
            key={index}
            value={lead}
            sx={{ paddingLeft: theme.spacing(4) }}
          >
            <Checkbox checked={leads.indexOf(lead) > -1} />
            <ListItemText primary={lead} />
          </MenuItem>
        ))}
      </Select>
      {leads.length === 0 && (
        <Typography
          sx={{
            position: "absolute",
            pointerEvents: "none",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            marginLeft: theme.spacing(1),
            color: theme.palette.error.main,
          }}
        >
          {t("ecgViewer.leads.noneSelected")}
        </Typography>
      )}
    </Box>
  );
};

export default LeadsConfiguration;
