import { NorthEast } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AdaptedDropzone from "../../components/Dropzone";
import Header from "../../components/Header";
import {
  resetState,
  updateAge,
  // updateAnalysisProgress,
  updateEcgProfile,
  updatePatientId,
  updateRecordingDuration,
  updateRisks,
  updateSex,
} from "../../state/patientSlice";
import { setMessage, setSeverity } from "../../state/snackbarSlice";
import DataTransfer, { pollingState } from "../../utils/dataTransfer";
import { styles } from "../sharedStyles";

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const CircularProgressWithLabel = (props) => {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${props.value}%`}
        </Typography>
      </Box>
    </Box>
  );
};

const Analyse = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  // Dopzone files
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [resetDropzone, setresetDropzone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [analysisProgress, setAnalysisProgress] = useState(0);

  const handleFilesChange = (files) => {
    setUploadedFiles(files);
  };

  // Redux
  const dispatch = useDispatch();
  const {
    patientId,
    age,
    sexOptions,
    sex,
    riskOptions,
    risks,
    recordingDurationOptions,
    recordingDuration,
    ecgProfile,
  } = useSelector((state) => state.patient);

  async function updateProgress() {
    for (let i = 0; i < 100; i++) {
      setAnalysisProgress(i);
      await delay(20);
    }
  }

  const handlePatientIdChange = (e) => {
    dispatch(updatePatientId(e.target.value));
  };

  const handleAge = (e) => {
    dispatch(updateAge(e.target.value));
  };

  const handleSexChange = (e) => {
    dispatch(updateSex(e.target.value));
  };

  const handleRisksChange = (e) => {
    dispatch(updateRisks(e.target.value));
  };

  const handleECGProfileChange = (e) => {
    dispatch(updateEcgProfile(e.target.value));
  };

  const handleRecordingDurationChange = (e) => {
    dispatch(updateRecordingDuration(e.target.value));
  };

  const handleResetState = () => {
    dispatch(resetState());
    setresetDropzone((prev) => !prev);
  };

  const user = useSelector((state) => state.user);

  const abortControllerRef = useRef(new AbortController());

  const handleAnalysis = () => {
    if (uploadedFiles.length === 0) {
      setLoading(false);
      dispatch(setMessage(t("snackbarMessages.analysisErrorMissingFiles")));
      dispatch(setSeverity("error"));
      return;
    }

    setAnalysisProgress(0);
    abortControllerRef.current = new AbortController();
    setLoading(true);
    updateProgress().then();

    dispatch(setMessage(t("snackbarMessages.analysisStart")));
    dispatch(setSeverity("info"));

    DataTransfer.predict(
      uploadedFiles,
      {
        patientId,
        age,
        sex,
        risks,
        recordingDuration,
        ecgProfile,
      },
      abortControllerRef.current
    ).then((response) => {
      if (response.status === 201){
        setAnalysisProgress(100);
        dispatch(setMessage(t("snackbarMessages.analysisSuccess")));
        dispatch(setSeverity("success"));
      }
      else {  // TODO: Fehlercodes unterscheiden
        setLoading(false);
        setAnalysisProgress(0);
        dispatch(setMessage(t("snackbarMessages.analysisError")));
        dispatch(setSeverity("error"));
        return;
      }

      setTimeout(() => {
        const pollingInterval = setInterval(() => {
          pollingState.isRunning = true;
          if (pollingState.stopInterval) {
            clearInterval(pollingInterval);
            pollingState.stopInterval = false;
            pollingState.isRunning = false;
          }

          DataTransfer.result(response['auth'], response['ticket'], dispatch).then((data) => {
            navigate(`/result/show`, { state: data });
          });
        }, 1000);
      }, 100);
    });
  };

  const handleAbort = () => {
    if (pollingState.isRunning) {
      pollingState.stopInterval = true;
    } else {
      abortControllerRef.current.abort();
    }
    setLoading(false);
    dispatch(setMessage(t("snackbarMessages.analysisAbort")));
    dispatch(setSeverity("warning"));
  };

  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      if (pollingState.isRunning) {
        pollingState.stopInterval = true;
      }

      setAnalysisProgress(0);
    };
  }, [dispatch]);

  return (
    <Box sx={styles(theme).mainContent}>
      <Header title={t("scenes.analysis.heading")} />

      <Grid container>
        <Grid item xs={12} md={6} xl={3} padding={"1%"}>
          <TextField
            id="patientId"
            label={t("scenes.analysis.inputs.patientId")}
            variant="standard"
            size="small"
            sx={{ width: "100%" }}
            value={patientId}
            onChange={handlePatientIdChange}
          />
        </Grid>

        <Grid item xs={12} md={6} xl={3} padding={"1%"}>
          <TextField
            id="age"
            label={t("scenes.analysis.inputs.patientAge")}
            variant="standard"
            size="small"
            type="number"
            sx={{ width: "100%" }}
            value={age}
            onChange={handleAge}
          />
        </Grid>

        <Grid item xs={12} md={6} xl={3} padding={"1%"}>
          <FormControl variant="standard" size="small" sx={{ width: "100%" }}>
            <InputLabel id="sex-label">
              {t("scenes.analysis.inputs.patientGender")}
            </InputLabel>
            <Select
              labelId="sex-label"
              id="sex"
              value={sex}
              onChange={handleSexChange}
              label="patientGender"
            >
              {sexOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {t(`scenes.analysis.options.gender.${option.value}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6} xl={3} padding={"1%"}>
          <FormControl variant="standard" size="small" sx={{ width: "100%" }}>
            <InputLabel id="recording-duration-label">
              {t("scenes.analysis.inputs.recordingLength")}
            </InputLabel>
            <Select
              labelId="recording-duration-label"
              id="recording-duration"
              value={recordingDuration}
              onChange={handleRecordingDurationChange}
              label="ecgLength"
            >
              {recordingDurationOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {t(`scenes.analysis.options.recordingLength.${option.value}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6} xl={3} padding={"1%"}>
          <FormControl variant="standard" size="small" sx={{ width: "100%" }}>
            <InputLabel id="ecgProfile-label">
              {t("scenes.analysis.inputs.ecgProfile")}
            </InputLabel>
            <Select
              labelId="ecgProfile-label"
              id="profile"
              value={ecgProfile}
              onChange={handleECGProfileChange}
              label="ecgProfile"
            >
              {user.ecgProfiles.map((profile, index) => (
                <MenuItem key={index} value={profile}>
                  <span style={{ fontWeight: theme.typography.fontWeightBold }}>
                    {profile.profileTitle}
                  </span>
                  &nbsp;
                  <span style={{ fontStyle: "italic" }}>
                    ({t(`ecgManufacturers.${profile.selectedManufacturer}`)})
                  </span>
                </MenuItem>
              ))}
              <MenuItem
                onClick={() => navigate("/config", { state: { tabIndex: 2 } })}
                value=""
              >
                <span style={{ fontStyle: "italic" }}>
                  {user.ecgProfiles.length === 0 &&
                    t("scenes.analysis.options.ecgProfile.none")}{" "}
                  {t("scenes.analysis.options.ecgProfile.goToSettings")}
                </span>
                &nbsp;
                <NorthEast />
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6} xl={6} padding={"1%"}>
          <FormControl variant="standard" size="small" sx={{ width: "100%" }}>
            <InputLabel id="risks-label" variant="standard">
              {t("scenes.analysis.inputs.optionalParameters")}
            </InputLabel>
            <Select
              labelId="risks-label"
              id="risks"
              multiple
              value={risks}
              onChange={handleRisksChange}
              renderValue={(selected) => {
                const selectedLabels = selected.map((item) =>
                  t(`scenes.analysis.options.optionalParameters.${item}`)
                );
                return selectedLabels.join(", ");
              }}
              sx={{ width: "100%" }}
            >
              {riskOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <Checkbox checked={risks.indexOf(option.value) > -1} />
                  <ListItemText
                    primary={t(
                      `scenes.analysis.options.optionalParameters.${option.value}`
                    )}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <AdaptedDropzone
        onFilesChanged={handleFilesChange}
        reset={resetDropzone}
        label={t("scenes.analysis.inputs.dropzone")}
        uploadedFiles={uploadedFiles}
        setUploadedFiles={setUploadedFiles}
      />

      <Grid container>
        <Grid item xs={6} md={3} xl={2} padding={"1%"}>
          <Button
            disabled={loading}
            variant="contained"
            sx={{
              width: "100%",
            }}
            onClick={() => handleAnalysis()}
          >
            {t("scenes.analysis.buttons.analyse")}
          </Button>
        </Grid>

        <Grid item xs={6} md={3} xl={2} padding={"1%"}>
          <Button
            disabled={loading}
            variant="contained"
            onClick={() => {
              handleResetState();
            }}
            sx={{
              width: "100%",
            }}
          >
            {t("scenes.analysis.buttons.reset")}
          </Button>
        </Grid>
        <Grid item xs={6} md={3} xl={2} padding={"1%"}>
          <Button
            disabled={!loading}
            variant="contained"
            onClick={handleAbort}
            sx={{
              width: "100%",
            }}
          >
            {t("scenes.analysis.buttons.abort")}
          </Button>
        </Grid>
        {loading && (
          <Grid item xs={12} marginY={theme.spacing(4)}>
            <Box
              justifyContent={"center"}
              alignItems={"center"}
              width={"fit-content"}
              marginX={"auto"}
            >
              <CircularProgressWithLabel value={analysisProgress} />
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Analyse;
