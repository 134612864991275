import { LanguageOutlined } from "@mui/icons-material";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import Logout from "@mui/icons-material/Logout";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import Settings from "@mui/icons-material/Settings";
import {
  Avatar,
  Badge,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  MenuItem,
  useTheme,
} from "@mui/material";
import InputBase from "@mui/material/InputBase";
import Menu from "@mui/material/Menu";
import axios from "axios";
import * as React from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AuthContext } from "../../auth/AuthContext";
import { languages } from "../../data/languages";
import { updateCurrentPage } from "../../state/sidebarSlice";
import {
  changeLanguagePreference,
  changeSearchText,
  changeThemePreference,
  updateUserSettings,
} from "../../state/userSlice";
import { ColorModeContext } from "../../theme";
import { backend_uri } from "../../utils/config_reader";

const Topbar = () => {
  const user = useSelector((state) => state.user);
  const { t, i18n } = useTranslation();

  const theme = useTheme();
  const { setMode } = useContext(ColorModeContext);

  const { logout } = useContext(AuthContext);

  const [anchorElProfile, setAnchorElProfile] = React.useState(null);
  const [anchorElLanguage, setAnchorElLanguage] = React.useState(null);

  const profileMenuOpen = Boolean(anchorElProfile);
  const languageMenuOpen = Boolean(anchorElLanguage);

  const handleProfileMenuOpen = (event) => {
    setAnchorElProfile(event.currentTarget);
  };
  const handleProfileMenuClose = () => {
    setAnchorElProfile(null);
  };

  const handleLanguageMenuOpen = (event) => {
    setAnchorElLanguage(event.currentTarget);
  };
  const handleLanguageMenuClose = () => {
    setAnchorElLanguage(null);
  };

  // Redux
  const dispatch = useDispatch();
  const handleCurrentPage = (title) => {
    dispatch(updateCurrentPage(title));
  };

  const handleThemeChange = (event) => {
    const newTheme = theme.palette.mode === "dark" ? "light" : "dark";
    dispatch(changeThemePreference(newTheme));
    setMode(newTheme);
    dispatch(updateUserSettings({ themePreference: newTheme }));
  };

  const handleLanguageChange = (language) => {
    dispatch(changeLanguagePreference(language));
    i18n.changeLanguage(language);
    dispatch(updateUserSettings({ languagePreference: language }));
  };

  const handleSearchText = (event) => {
    dispatch(changeSearchText(event.target.value));
  };

  return (
    <>
      <Box id="topbar" display="flex" justifyContent="space-between" p={2}>
        {/* SEARCH BAR */}
        <Box
          display="flex"
          backgroundColor={theme.palette.background.darker}
          borderRadius={theme.shape.borderRadius}
        >
          <InputBase
            sx={{ ml: 2, flex: 1 }}
            placeholder={t("topbar.search")}
            onChange={handleSearchText}
          />
          <IconButton type="button" sx={{ p: 1 }}>
            <SearchIcon />
          </IconButton>
        </Box>

        {/* ICONS */}
        <Box display="flex">
          <IconButton onClick={handleThemeChange}>
            {user.themePreference === "dark" ? (
              <DarkModeOutlinedIcon />
            ) : (
              <LightModeOutlinedIcon />
            )}
          </IconButton>
          <IconButton onClick={handleLanguageMenuOpen}>
            <Badge badgeContent={user.languagePreference.toUpperCase()}>
              <LanguageOutlined />
            </Badge>
          </IconButton>
          <IconButton onClick={handleProfileMenuOpen}>
            <PersonOutlinedIcon />
          </IconButton>
        </Box>
      </Box>
      <Menu
        anchorEl={anchorElProfile}
        id="account-menu"
        open={profileMenuOpen}
        onClose={handleProfileMenuClose}
        onClick={handleProfileMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem>
          <Avatar /> {localStorage.getItem("user")}
        </MenuItem>
        <Divider />
        <MenuItem
          component={Link}
          to="/config"
          onClick={() => handleCurrentPage("Einstellungen")}
        >
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          {t("topbar.settings")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            axios.post(`${backend_uri}/api/user/logout/`, {
              refresh: localStorage.getItem("refresh"),
            });
            logout();
          }}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {t("topbar.logout")}
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={anchorElLanguage}
        id="language-menu"
        open={languageMenuOpen}
        onClose={handleLanguageMenuClose}
        onClick={handleLanguageMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {languages.map((language, index) => (
          <MenuItem
            key={index}
            selected={user.languagePreference === language.code}
            onClick={() => handleLanguageChange(language.code)}
          >
            {language.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default Topbar;
