import { Box, MenuItem, Select, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ColorThemePresets } from "../../../data/colorTheme";
import {
  changeEcgViewerColors,
  updateUserSettings,
} from "../../../state/userSlice";
import ColorPicker from "../../ColorPicker";
import { LabelledInput } from "../LabelledInputs";

/**
 * Utility function to sort the entries of an object by its keys.
 * 
 * @param {{}} object - Object, which keys should be sorted.
 * @returns {}
 */
const sortObjectKeys = (object) => {
  const sortedKeys = Object.keys(object).sort();

  const sortedObject = {};
  sortedKeys.forEach((key) => {
    sortedObject[key] = object[key];
  });

  return sortedObject;
};

/**
 * Utility function to check if two objects are equal.
 * 
 * @param {{}} obj1 - The first object to be compared to the second one.
 * @param {{}} obj2 - The second object to be compared to the first one.
 * @returns {}
 */
const objectsAreEqual = (obj1, obj2) => {
  return (
    JSON.stringify(sortObjectKeys(obj1)) ===
    JSON.stringify(sortObjectKeys(obj2))
  );
};

/**
 * Settings panel for the ecg colors.
 * 
 * @component
 * @example
 * 
 * return (
 *  <ColorCustomizationPanel />
 * )
 */

const ColorCustomizationPanel = () => {
  const colorThemePresets = ColorThemePresets();
  const { t } = useTranslation();
  const theme = useTheme();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [themePreset, setThemePreset] = useState("default");

  const ecgViewerSettings = [
    "grid",
    "highlight",
    "ecg",
    "measure",
    "timeline",
    "visuals",
  ];

  const handleThemePresetChange = (event) => {
    setThemePreset(event.target.value);
    const colors = colorThemePresets[event.target.value].colors;
    dispatch(changeEcgViewerColors(colors));
    dispatch(updateUserSettings({ ecgViewerColors: colors }));
  };

  useEffect(() => {
    if (
      objectsAreEqual(user.ecgViewerColors, colorThemePresets["default"].colors)
    ) {
      setThemePreset("default");
    } else if (
      objectsAreEqual(
        user.ecgViewerColors,
        colorThemePresets["red_green_support"].colors
      )
    ) {
      setThemePreset("red_green_support");
    }
  }, [user.ecgViewerColors, colorThemePresets]);

  return (
    <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
      <LabelledInput label={t("scenes.config.tabs.ecgViewer.colorTheme.label")}>
        <Select
          id="theme-preset-select"
          value={themePreset}
          onChange={handleThemePresetChange}
        >
          {Object.values(ColorThemePresets()).map((colorTheme, index) => (
            <MenuItem key={index} value={colorTheme.value}>
              {t(
                `scenes.config.tabs.ecgViewer.colorTheme.options.${colorTheme.value}`
              )}
            </MenuItem>
          ))}
        </Select>
      </LabelledInput>
      <Box marginY={theme.spacing(2)} />
      {ecgViewerSettings.map((setting, index) => (
        <LabelledInput
          key={index}
          label={t(
            `scenes.config.tabs.ecgViewer.advancedSettings.colors.labels.${setting}`
          )}
        >
          <ColorPicker
            color={user.ecgViewerColors[setting]}
            setting={setting}
          />
        </LabelledInput>
      ))}
    </Box>
  );
};

export default ColorCustomizationPanel;
