import { Grid, useTheme } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AccordionContent, AccordionWrapper } from "../../components/Accordion";
import BaseCustomizationPanel from "../../components/Config/AdvancedSettings/BaseCustomizationPanel";
import ColorCustomizationPanel from "../../components/Config/AdvancedSettings/ColorCustomizationPanel";
import KpiCustomizationPanel from "../../components/Config/AdvancedSettings/KpiCustomizationPanel";
import { ECGViewer } from "../../components/ECGViewer";
import { PresetColors } from "../../data/colorTheme";
import previewEcg from "../../data/previewEcg.json";
import { kpiInformation } from "../../data/resultLookUps";

const EcgViewerSettings = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  const theme = useTheme();

  const [kpiArray] = useState(Object.keys(kpiInformation));
  const [expanded, setExpanded] = useState(false);

  let kpiReadableValues = [];
  kpiArray.forEach((kpi, index) => {
    kpiReadableValues[index] = kpiInformation[kpi].label;
  });

  const handleChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const ecgViewerColors = {};

  for (const [key, value] of Object.entries(user.ecgViewerColors)) {
    ecgViewerColors[key] = PresetColors()[value].code;
  }

  return (
    <Grid container>
      <Grid
        item
        md={8}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <AccordionWrapper>
          <AccordionContent
            panel={"kpiOrder"}
            header={t(
              "scenes.config.tabs.ecgViewer.advancedSettings.kpiOrder.label"
            )}
            headerFontWeight="bold"
            handleChange={handleChange}
            expanded={expanded}
          >
            <KpiCustomizationPanel kpiArray={kpiArray} />
          </AccordionContent>
          <AccordionContent
            panel={"leads"}
            header={t(
              "scenes.config.tabs.ecgViewer.advancedSettings.base.label"
            )}
            headerFontWeight="bold"
            handleChange={handleChange}
            expanded={expanded}
          >
            <BaseCustomizationPanel />
          </AccordionContent>
          <AccordionContent
            panel={"colors"}
            header={t(
              "scenes.config.tabs.ecgViewer.advancedSettings.colors.label"
            )}
            headerFontWeight="bold"
            handleChange={handleChange}
            expanded={expanded}
          >
            <ColorCustomizationPanel />
          </AccordionContent>
        </AccordionWrapper>
      </Grid>
      <Grid
        item
        md={4}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <ECGViewer
          singleColumn={user.singleColumn}
          data={previewEcg}
          idAppendix={"preview"}
          currentExplanation={"RBBB_explanation"}
          ecgWidth={"40em"}
          ecgHeight={"30em"}
          leads={user.leadsPreference}
          speed={user.speedPreference}
          measure={"ruler"}
          displayed={["grid", "explainability"]}
          colors={ecgViewerColors}
          font={theme.typography.h2}
          snap={""}
          zoomCallback={() => {}}
          translations={t}
        />
      </Grid>
    </Grid>
  );
};

export default EcgViewerSettings;
