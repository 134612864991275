import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  message: "",
  severity: "info",
};

const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    setMessage: (state, action) => {
      state.message = action.payload;
    },
    setSeverity: (state, action) => {
      state.severity = action.payload;
    },
  },
});

export const { setMessage, setSeverity } = snackbarSlice.actions;

export default snackbarSlice.reducer;
