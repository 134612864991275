import { createSlice } from "@reduxjs/toolkit";
import { riskList } from "../data/riskLabels";

const riskOptions = riskList.map((risk, index) => {
  return {
    value: index,
    label: risk,
  };
});

const initialState = {
  patientId: "",
  age: "",
  sex: "",
  sexOptions: [
    { value: "male", label: "Männlich" },
    { value: "female", label: "Weiblich" },
    { value: "other", label: "Keine Angabe" },
  ],
  riskOptions,
  risks: [],
  recordingDuration: "",
  recordingDurationOptions: [
    { value: "five", label: "5 Sekunden" },
    { value: "ten", label: "10 Sekunden" },
    { value: "auto", label: "Automatisch erkennen" },
  ],
  ecgProfile: "",
  ecgProfileOptions: ["Kein Profil vorhanden"],
  analysisProgress: 0,
};

const patientSlice = createSlice({
  name: "patient",
  initialState,
  reducers: {
    updatePatientId: (state, action) => {
      state.patientId = action.payload;
    },
    updateAge: (state, action) => {
      state.age = action.payload;
    },
    updateSex: (state, action) => {
      state.sex = action.payload;
    },
    updateRisks: (state, action) => {
      state.risks = action.payload;
    },
    updateRecordingDuration: (state, action) => {
      state.recordingDuration = action.payload;
    },
    updateEcgProfile: (state, action) => {
      state.ecgProfile = action.payload;
    },
    updateEcgProfileOptions: (state, action) => {
      state.ecgProfileOptions = action.payload;
    },
    updateAnalysisProgress: (state, action) => {
      state.analysisProgress = action.payload;
    },
    resetState: (state) => {
      state.patientId = initialState.patientId;
      state.age = initialState.age;
      state.sex = initialState.sex;
      state.recordingDuration = initialState.recordingDuration;
      state.risks = initialState.risks;
      state.ecgProfile = initialState.ecgProfile;
    },
  },
});

export const {
  updatePatientId,
  updateAge,
  updateSex,
  updateRisks,
  updateRecordingDuration,
  updateEcgProfile,
  updateEcgProfileOptions,
  updateAnalysisProgress,
  resetState,
} = patientSlice.actions;

export default patientSlice.reducer;
