import config from "./config";
import controller from "./controller";
import viewer from "./viewer";

const eventHandlers = {
  canvas: {
    mouseover: (event) => {
      config.movement.moveActive = false;
    },
    mousemove: (event) => {
      if (config.movement.moveActive && !config.globalProps.mark) {
        controller.handleMove(event);
        viewer.draw();
        return;
      }
      if (config.ruler.rulerTurnActive) {
        config.ruler.rulerAngleChanged = true;
        config.ruler.rulerAngle +=
          (event.movementY < 0 ? -1 : 1) * Math.PI * 0.01;
        viewer.redrawMeasure(config.canvas.measure.getContext("2d"));
        return;
      }
      if (
        !(event.nativeEvent.offsetX === 0 || event.nativeEvent.offsetY === 0)
      ) {
        config.movement.lastMoveEvent = {
          nativeEvent: {
            offsetX: event.nativeEvent.offsetX,
            offsetY: event.nativeEvent.offsetY,
          },
        };
      }
      if (config.globalProps.measure !== "") {
        controller.markClosestNode(event);
        viewer.redrawMeasure(config.canvas.measure.getContext("2d"));
      }
      if (config.globalProps.mark) {
        controller.markClosestNode(event);
        viewer.redrawMarkings(config.canvas.marking.getContext("2d"));
      }
    },
    click: (event) => {
      if (config.globalProps.measure === "digital") {
        if (!config.movement.changed) {
          controller.setMeasure();
          viewer.redrawMeasure(config.canvas.measure.getContext("2d"));
        } else {
          config.movement.changed = false;
        }
      }
    },
    mouseleave: (event) => {
      config.measurement.closestNode = -1;
      if (config.globalProps.measure === "digital")
        viewer.redrawMeasure(config.canvas.measure.getContext("2d"));
      if (config.globalProps.mark)
        viewer.redrawMarkings(config.canvas.marking.getContext("2d"));
    },
    wheel: (event) => {
      event.preventDefault();
      if (config.movement.zoomProcessing) return;

      config.movement.zoomProcessing = true;

      controller.handleZoom(event);
      viewer.draw();

      config.movement.zoomProcessing = false;
    },
    mousedown: (event) => {
      switch (event.button) {
        case 0:
          if (config.globalProps.mark) {
            controller.setMarkings();
            config.movement.markActive = true;
          } else {
            config.movement.moveActive = true;
          }
          break;
        case 1:
          event.preventDefault();
          if (config.globalProps.measure === "ruler") {
            config.ruler.rulerTurnActive = true;
          } else if (config.globalProps.measure === "digital") {
            config.measurement.measure = [];
            viewer.redrawMeasure(config.canvas.measure.getContext("2d"));
          }
          break;
        case 2:
          break;
        default:
          break;
      }
    },
    mouseup: (event) => {
      switch (event.button) {
        case 0:
          if (config.globalProps.mark) {
            controller.setMarkings();
            config.movement.markActive = false;
          } else {
            config.movement.moveActive = false;
          }
          break;
        case 1:
          if (config.globalProps.measure === "ruler") {
            if (!config.ruler.rulerAngleChanged) {
              let x =
                (Math.floor(config.ruler.rulerAngle / (Math.PI / 2)) + 1) % 4;
              config.ruler.rulerAngle = (x * Math.PI) / 2;
              viewer.redrawMeasure(config.canvas.measure.getContext("2d"));
            }
            config.ruler.rulerTurnActive = false;
            config.ruler.rulerAngleChanged = false;
          }
          break;
        case 2:
          break;
        default:
          break;
      }
    },
  },
};

export default eventHandlers;
