import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Paper,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../auth/AuthContext";
import CustomSnackbar from "../../components/Snackbar";
import kardioiqlogoblack from "../../resources/kardioiqlogoblack.png";
// import kardioiqlogoblack from "../../resources/hermiqslogoblack.png";
import { backend_uri } from "../../utils/config_reader";

const KardioiqForm = ({ children, handleSubmit, theme }) => {
  return (
    <Paper
      style={{
        padding: theme.spacing(3),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <img
        src={kardioiqlogoblack}
        alt="Logo"
        style={{ width: 300, margin: `${theme.spacing(2)} 0` }}
      />
      <form onSubmit={handleSubmit}>{children}</form>
    </Paper>
  );
};

function LoginPage() {
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("user")) {
      navigate("/analyse");
    }
  }, [navigate]);

  const { login } = useContext(AuthContext);

  const [showPassword, setShowPassword] = useState(false);
  const [hasErrorMessage, setHasErrorMessage] = useState(false);
  const [hasSuccessMessage, setHasSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [loginData, setLoginData] = useState({
    username: "",
    password: "",
  });

  const [registerData, setRegisterData] = useState({
    username: "",
    email: "",
    password: "",
    passwordRepeat: "",
  });

  const [registration, setRegistration] = useState(false);

  const handleLogin = async () => {
    setLoading(true);
    try {
      if (!loginData.username.trim() || !loginData.password.trim()) {
        setHasErrorMessage(true);
        setErrorMessage("Bitte füllen Sie alle Felder aus.");
        setLoading(false);
        return;
      }
      setHasErrorMessage(false);

      const response = await axios.post(`${backend_uri}/api/token/obtain/`, {
        username: loginData.username,
        password: loginData.password,
      });

      const accessToken = response.data.access;
      const refreshToken = response.data.refresh;

      localStorage.setItem("access", accessToken);
      localStorage.setItem("refresh", refreshToken);

      login(accessToken);
      navigate("/analyse");
    } catch (error) {
      console.error(error);
      setLoading(false);
      setHasErrorMessage(true);
      setErrorMessage("Nutzerdaten nicht korrekt.");
    }
  };

  const handleRegister = async () => {
    try {
      if (
        !registerData.username.trim() ||
        !registerData.email.trim() ||
        !registerData.password.trim() ||
        !registerData.passwordRepeat.trim()
      ) {
        setHasErrorMessage(true);
        setErrorMessage("Bitte füllen Sie alle Felder aus.");
        return;
      }

      if (registerData.password !== registerData.passwordRepeat) {
        setHasErrorMessage(true);
        setErrorMessage("Die Passwörter stimmen nicht überein.");
        return;
      }
      setHasErrorMessage(false);

      const response = await axios.post(`${backend_uri}/api/user/register/`, {
        username: registerData.username,
        email: registerData.email,
        password: registerData.password,
      });

      if (response.status === 201) {
        setRegistration(false);
        setHasSuccessMessage(true);
        setSuccessMessage(
          "Nutzer wurde erfolgreich angelegt. Sie können sich nun einloggen."
        );
        setRegisterData({
          username: "",
          email: "",
          password: "",
          passwordRepeat: "",
        });
      } else {
        setHasErrorMessage(true);
        setErrorMessage("Nutzer wurde nicht angelegt!");
      }
    } catch (error) {
      console.error("Registration failed:", error);
      setHasErrorMessage(true);
      setErrorMessage("Etwas ist schief gelaufen. Probieren Sie es erneut.");
    }
  };

  const handleLoginSubmit = (event) => {
    event.preventDefault();
    handleLogin();
  };

  const handleRegisterSubmit = (event) => {
    event.preventDefault();
    handleRegister();
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      style={{
        display: "flex",
        height: "100vh",
        alignItems: "center",
      }}
    >
      {registration ? (
        <KardioiqForm handleSubmit={handleRegisterSubmit} theme={theme}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                label="Username"
                value={registerData.username}
                onChange={(e) =>
                  setRegisterData({ ...registerData, username: e.target.value })
                }
                error={hasErrorMessage}
                sx={{
                  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "darkred",
                    },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "darkred",
                    },
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "darkred",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="email"
                variant="outlined"
                required
                fullWidth
                label="Email Address"
                value={registerData.email}
                onChange={(e) =>
                  setRegisterData({ ...registerData, email: e.target.value })
                }
                error={hasErrorMessage}
                sx={{
                  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "darkred",
                    },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "darkred",
                    },
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "darkred",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth error={hasErrorMessage}>
                <InputLabel
                  htmlFor="outlined-adornment-password"
                  sx={{
                    "&.Mui-focused": {
                      color: "darkred",
                    },
                  }}
                >
                  Passwort
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  required
                  type={showPassword ? "text" : "password"}
                  value={registerData.password}
                  onChange={(e) =>
                    setRegisterData({
                      ...registerData,
                      password: e.target.value,
                    })
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                  sx={{
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "darkred",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "darkred",
                    },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth error={hasErrorMessage}>
                <InputLabel
                  htmlFor="outlined-adornment-password-repeat"
                  sx={{
                    "&.Mui-focused": {
                      color: "darkred",
                    },
                  }}
                >
                  Passwort wiederholen
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password-repeat"
                  required
                  type={showPassword ? "text" : "password"}
                  value={registerData.passwordRepeat}
                  onChange={(e) =>
                    setRegisterData({
                      ...registerData,
                      passwordRepeat: e.target.value,
                    })
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Passwort wiederholen"
                  sx={{
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "darkred",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "darkred",
                    },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button
                disabled={loading}
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  backgroundColor: "red",
                  "&:hover": {
                    backgroundColor: "darkred",
                  },
                }}
              >
                Register
              </Button>
            </Grid>
            {loading && (
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <CircularProgress />
              </Grid>
            )}
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Link
                onClick={() => {
                  setRegistration(false);
                  setHasErrorMessage(false);
                  setHasSuccessMessage(false);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
                href="#"
                variant="body2"
                color="error"
              >
                {" "}
                Login
              </Link>
            </Grid>
          </Grid>
          {hasErrorMessage && (
            <CustomSnackbar
              message={<Typography variant="h4">{errorMessage}</Typography>}
              severity="error"
            />
          )}
          {hasSuccessMessage && (
            <CustomSnackbar
              message={<Typography variant="h4">{successMessage}</Typography>}
              severity="success"
            />
          )}
        </KardioiqForm>
      ) : (
        <KardioiqForm handleSubmit={handleLoginSubmit} theme={theme}>
          <Typography
            component="h1"
            variant="h5"
            color="error"
            style={{ marginBottom: theme.spacing(2) }}
            align="center"
          >
            {/*Risk Stratification for Cardiac Diseases*/}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                label="Nutzername"
                value={loginData.username}
                onChange={(e) =>
                  setLoginData({ ...loginData, username: e.target.value })
                }
                error={hasErrorMessage}
                sx={{
                  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "darkred",
                    },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "darkred",
                    },
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "darkred",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth error={hasErrorMessage}>
                <InputLabel
                  htmlFor="outlined-adornment-password"
                  sx={{
                    "&.Mui-focused": {
                      color: "darkred",
                    },
                  }}
                >
                  Passwort
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  required
                  type={showPassword ? "text" : "password"}
                  value={loginData.password}
                  onChange={(e) =>
                    setLoginData({ ...loginData, password: e.target.value })
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Passwort"
                  sx={{
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "darkred",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "darkred",
                    },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button
                disabled={loading}
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  backgroundColor: "red",
                  "&:hover": {
                    backgroundColor: "darkred",
                  },
                }}
              >
                Einloggen
              </Button>
            </Grid>
            {loading && (
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <CircularProgress />
              </Grid>
            )}
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Link href="#" variant="body2" color="error">
                {" "}
                Passwort vergessen?
              </Link>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Link
                onClick={() => {
                  setRegistration(true);
                  setHasErrorMessage(false);
                  setHasSuccessMessage(false);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
                href="#"
                variant="body2"
                color="error"
              >
                {" "}
                Registrieren
              </Link>
            </Grid>
          </Grid>
          {hasErrorMessage && (
            <CustomSnackbar
              message={<Typography variant="h4">{errorMessage}</Typography>}
              severity="error"
            />
          )}
          {hasSuccessMessage && (
            <CustomSnackbar
              message={<Typography variant="h4">{successMessage}</Typography>}
              severity="success"
            />
          )}
        </KardioiqForm>
      )}
    </Container>
  );
}

export default LoginPage;
