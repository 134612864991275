export const styles = (theme) => ({
  mainContent: {
    bgcolor: theme.palette.background.darker,
    width: "90%",
    height: "auto",
    marginTop: "1%",
    marginBottom: "1%",
    marginLeft: "auto",
    marginRight: "auto",
    padding: theme.spacing(2),
    boxShadow: 1,
    borderRadius: theme.shape.borderRadius,
  },
});

export const DefaultEcgColors = () => {
  return {
    grid: "grey",
    highlight: "red",
    ecg: "neutral",
    measure: "green",
    timeline: "neutral",
    visuals: "neutral",
  };
};
