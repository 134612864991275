import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, useTheme } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import React from "react";

/**
 * Container made for the AccordionContent component.
 *
 * @component
 * @example
 * return (
 *  <AccordionWrapper>
 *    <AccordionContent>
 *      {...}
 *    </AccordionContent>
 *  </AccordionWrapper>
 * )
 *
 * @param {React.FC} children - The children react components to be rendered inside.
 */
export const AccordionWrapper = ({ children }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        bgcolor: theme.palette.background.darker,
        margin: `${theme.spacing(2)} auto`,
      }}
    >
      {children}
    </Box>
  );
};

/**
 * A single accordion content component
 * 
 * @component
 * @example
 * const [expanded, setExpanded] = useState(false);
 * const handleChange = () => {}
 * 
 * return (
 *  <AccordionContent
 *    panel={"panelName"}
 *    header={"headerName"}
 *    handleChange={handleChange}
 *    expanded={expanded}
 *  >
 *    {...}
 *  </AccordionContent>
 * )
 * 
 * @param {React.FC} children - The children react components to be rendered inside.
 * @param {string} panel - Identification of the panel. Should be a unique name.
 * @param {string} header - The rendered title of the accordion panel.  
 * @param {CallableFunction} handleChange - Callback function that gets called, when the accordion is clicked
 * @param {boolean} expanded - Boolean, that gets set to true, when the accordion should be expanded
 * @param {"light" | "regular" | "bold" | number} headerFontWeight - Optional: The font weight of the rendered header
 * @returns 
 */
export const AccordionContent = ({
  children,
  panel,
  header,
  handleChange,
  expanded,
  headerFontWeight = "regular",
}) => {
  const theme = useTheme();

  return (
    <Accordion
      sx={{ bgcolor: theme.palette.background.dark }}
      expanded={expanded === panel}
      onChange={handleChange(panel)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${panel}-content`}
        id={`${panel}-header`}
      >
        <Typography
          color={theme.palette.text.light}
          variant="h5"
          fontWeight={headerFontWeight}
          sx={{ width: "50%", flexShrink: 0 }}
        >
          {header}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};
