import {
  Add,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
  Remove,
} from "@mui/icons-material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { kpiInformation } from "../data/resultLookUps";
import { changeKpiOrder, updateUserSettings } from "../state/userSlice";

/**
 * Sort list items between two columns.
 * 
 * @component
 * @example
 * return (
 *  <SortableList items={["Item1", "Item2", "Item3"]} />
 * )
 * 
 * @param {[]} items - The items that should be sorted in the component. 
 */
function SortableList({ items }) {
  const theme = useTheme();
  const { t } = useTranslation();

  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [activeItems, setActiveItems] = useState(user.kpiOrder)
  const [inactiveItems, setInactiveItems] = useState(
    items.filter((item) => !activeItems.includes(item))
  );

  const listItemHeight = 50;

  const moveItem = (list, setList, index, direction) => {
    const newPosition = index + direction;
    if (newPosition < 0 || newPosition >= list.length) return;

    const newItems = [...list];
    const item = newItems.splice(index, 1)[0];
    newItems.splice(newPosition, 0, item);

    setList(newItems);
    dispatch(changeKpiOrder(newItems));
    dispatch(updateUserSettings({ kpiOrder: newItems }));
  };

  const swapItem = (sourceList, targetList, index) => {
    const itemToSwap = sourceList[index];
    const newSourceList = [...sourceList];
    newSourceList.splice(index, 1);

    sourceList === activeItems
      ? setActiveItems(newSourceList)
      : setInactiveItems(newSourceList);

    if (sourceList === activeItems) {
      dispatch(changeKpiOrder(newSourceList));
      dispatch(updateUserSettings({ kpiOrder: newSourceList }));
    }
    const newTargetList = [...targetList];
    newTargetList.push(itemToSwap);

    sourceList === activeItems
      ? setInactiveItems(newTargetList)
      : setActiveItems(newTargetList);

    if (sourceList !== activeItems) {
      dispatch(changeKpiOrder(newTargetList));
      dispatch(updateUserSettings({ kpiOrder: newTargetList }));
    }
  };

  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"space-evenly"}
        alignItems={"center"}
        borderRadius={theme.shape.borderRadius}
        padding={theme.spacing(2)}
        margin={4}
      >
        <Box display={"flex"} flexDirection={"column"}>
          <Typography
            textAlign={"center"}
            marginBottom={theme.spacing(2)}
            variant="h4"
          >
            {t("scenes.config.tabs.ecgViewer.advancedSettings.kpiOrder.shown")}
          </Typography>
          <List
            sx={{
              width: 300,
              minHeight: items.length * listItemHeight + 100,
              display: "flex",
              flexDirection: "column",
              justifyContent: activeItems.length <= 0 ? "center" : "flex-start",
            }}
          >
            {activeItems.length <= 0 && (
              <Typography textAlign={"center"} variant="h6" color={"error"}>
                {t(
                  "scenes.config.tabs.ecgViewer.advancedSettings.kpiOrder.noneShown"
                )}
              </Typography>
            )}
            {activeItems.map((item, index) => (
              <ListItem
                key={index}
                sx={{
                  backgroundColor: theme.palette.background.darker,
                  borderRadius: theme.shape.borderRadius / 2,
                  marginBlock: 0.5,
                  height: listItemHeight,
                  boxShadow: 1,
                }}
              >
                <ListItemText
                  primary={`${index + 1}. ${kpiInformation[item].label}`}
                />
                <Box>
                  <IconButton
                    onClick={() =>
                      moveItem(activeItems, setActiveItems, index, -1)
                    }
                    disabled={index === 0}
                  >
                    <ArrowUpwardIcon />
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      moveItem(activeItems, setActiveItems, index, 1)
                    }
                    disabled={index === activeItems.length - 1}
                  >
                    <ArrowDownwardIcon />
                  </IconButton>
                  <IconButton
                    color="warning"
                    onClick={() => swapItem(activeItems, inactiveItems, index)}
                  >
                    <Remove />
                  </IconButton>
                </Box>
              </ListItem>
            ))}
          </List>
        </Box>
        <Box display={"flex"} flexDirection={"column"}>
          <KeyboardDoubleArrowRight
            sx={{
              fontSize: theme.typography.h1.fontSize,
              color: theme.palette.text.disabled,
            }}
          />
          <KeyboardDoubleArrowLeft
            sx={{
              fontSize: theme.typography.h1.fontSize,
              color: theme.palette.text.disabled,
            }}
          />
        </Box>
        <Box>
          <Typography
            textAlign={"center"}
            marginBottom={theme.spacing(2)}
            variant="h4"
          >
            {t("scenes.config.tabs.ecgViewer.advancedSettings.kpiOrder.hidden")}
          </Typography>
          <List
            sx={{
              width: 300,
              minHeight: items.length * listItemHeight + 100,
              display: "flex",
              flexDirection: "column",
              justifyContent:
                inactiveItems.length <= 0 ? "center" : "flex-start",
            }}
          >
            {inactiveItems.length <= 0 && (
              <Typography textAlign={"center"} variant="h6">
                {t(
                  "scenes.config.tabs.ecgViewer.advancedSettings.kpiOrder.noneHidden"
                )}
              </Typography>
            )}
            {inactiveItems.map((item, index) => (
              <ListItem
                key={index}
                sx={{
                  backgroundColor: theme.palette.background.darker,
                  borderRadius: theme.shape.borderRadius / 2,
                  marginBlock: 0.5,
                  height: listItemHeight,
                  boxShadow: 1,
                }}
              >
                <Box>
                  <IconButton
                    color="success"
                    onClick={() => swapItem(inactiveItems, activeItems, index)}
                  >
                    <Add />
                  </IconButton>
                </Box>
                <ListItemText
                  primary={`${index + 1}. ${kpiInformation[item].label}`}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </>
  );
}

export default SortableList;
