// Fixme
const config = {
  production: false,
  production_backend: "http://127.0.0.1:8000",
  test_backend: "http://127.0.0.1:8000",
};

let backend_uri;

if (config.production) {
  backend_uri = config.production_backend;
} else {
  backend_uri = config.test_backend;
}

module.exports = { backend_uri };
