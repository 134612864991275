const colors = {
  grid: "",
  gridCM: "",
  highlight: "",
  ecg: "",
  measure: "",
  timeline: "",
  visuals: "",
};

export default colors;
