import { Box, MenuItem, Select, Switch } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  changeSingleColumn,
  changeSpeedPreference,
  updateUserSettings,
} from "../../../state/userSlice";
import LeadsConfiguration from "../../LeadsConfiguration";
import { LabelledInput } from "../LabelledInputs";

/**
 * Settings panel for base settings.
 *
 * @component
 * @example
 *
 * return (
 *  <BaseCustomizationPanel />
 * )
 */
const BaseCustomizationPanel = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [speed, setSpeed] = useState(user.speedPreference);

  const handleSpeedChange = (event) => {
    setSpeed(event.target.value);
    dispatch(changeSpeedPreference(event.target.value));
    dispatch(updateUserSettings({ speedPreference: event.target.value }));
  };

  const handleSingleColumnChange = () => {
    dispatch(changeSingleColumn(!user.singleColumn));
  };

  return (
    <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
      <LabelledInput
        label={t(
          "scenes.config.tabs.ecgViewer.advancedSettings.base.labels.leads"
        )}
      >
        <LeadsConfiguration saveSettings={true} />
      </LabelledInput>
      <LabelledInput
        label={t(
          "scenes.config.tabs.ecgViewer.advancedSettings.base.labels.speed"
        )}
      >
        <Select id="speed-select" value={speed} onChange={handleSpeedChange}>
          <MenuItem value={25}>{t("ecgViewer.speed.twentyfive")}</MenuItem>
          <MenuItem value={50}>{t("ecgViewer.speed.fifty")}</MenuItem>
          <MenuItem value={100}>{t("ecgViewer.speed.onehundred")}</MenuItem>
        </Select>
      </LabelledInput>
      <LabelledInput
        label={t(
          "scenes.config.tabs.ecgViewer.advancedSettings.base.labels.singleColumn"
        )}
      >
        <Switch
          checked={user.singleColumn}
          onChange={handleSingleColumnChange}
        />
      </LabelledInput>
    </Box>
  );
};

export default BaseCustomizationPanel;
