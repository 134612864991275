export const languages = [
  {
    label: "Deutsch",
    code: "de",
  },
  {
    label: "English",
    code: "en",
  },
];
